/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';
import { updateAttachment, uploadAttachment } from '../../helper/services/apis/candidate-services';
import { MESSAGES } from '../../constants';
import Loader from '../Loader';

function AddAttachment({ isOpen, closeModel = null, selectedFile = null, candidateId }) {
    const [dragActive, setDragActive] = React.useState(false);
    const [isFile, setIsFile] = useState([]);
    const [files, setFiles] = useState([]);
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        console.log('event : ', event.target.files);
        setFiles([...event.target.files]);
    };

    const onDescriptionChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const target = { ...event.target };
        setDescription(target.value);
    };

    const onSubmitHandler = async () => {
        setIsLoading(true);
        if (selectedFile) {
            let payload = {
                description,
                candidateId
            };

            if (files.length) {
                payload = {
                    ...payload,
                    media: files[0]
                };
            }
            const result = await updateAttachment(selectedFile.id, payload).catch((e) => {
                console.log(e);
                setIsLoading(false);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                setIsLoading(false);
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            }
        } else {
            const result = await uploadAttachment({
                media: files[0],
                description,
                candidateId
            }).catch((e) => {
                console.log(e);
                setIsLoading(false);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                setIsLoading(false);
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            }
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFiles([...e.dataTransfer.files]);
        }
    };

    const removeFile = () => {
        setIsFile(false);
    };

    useEffect(() => {
        if (selectedFile) {
            setDescription(selectedFile.description);
            setIsFile(!!selectedFile.name);
        }
    }, [selectedFile]);

    return (
        <ModalComponent isOpen={isOpen} closeModel={() => closeModel(false)} title="Allega file">
            {/* Modal Body */}
            {isLoading ? <Loader /> : null}
            <div className="p-4 input-field-group">
                <label>Descrizione :</label>
                <textarea
                    className="w-full"
                    rows={2}
                    value={description}
                    onChange={onDescriptionChange}
                    placeholder="Inserisci descrizione (facoltativa)"
                />
            </div>
            <div className="p-4">
                <div className="mb-2 text-sm font-bold text-grey-500">
                    File :{' '}
                    {selectedFile &&
                        !isFile &&
                        '(Caricando un nuovo file il precedente verrà sostituito)'}
                </div>
                <div>
                    {selectedFile && isFile ? (
                        <div className="flex space-x-3 justify-center items-center">
                            <span className="font-bold">{selectedFile.name}</span>
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="text-red-500 cursor-pointer"
                                onClick={() => removeFile()}
                            />
                        </div>
                    ) : files.length > 0 ? (
                        <div className="flex flex-col justify-center items-center">
                            {files.map((item) => (
                                <span key={item.name} className="font-bold">
                                    {item.name}
                                </span>
                            ))}
                        </div>
                    ) : (
                        <div
                            className={`transition ease-in-out space-y-6 flex flex-col w-full justify-center items-center p-12 border-dashed border-2 box-border ${
                                dragActive ? 'border-4 border-primary-800' : ''
                            }`}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}>
                            <label htmlFor="candidate-attachment">
                                <svg
                                    width="71"
                                    height="64"
                                    viewBox="0 0 71 64"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="cursor-pointer">
                                    <path
                                        d="M39.05 24.8889H56.8L35.5 46.2222L14.2 24.8889H31.95V0H39.05V24.8889ZM7.1 56.8889H63.9V32H71V60.4444C71 61.3874 70.626 62.2918 69.9602 62.9586C69.2945 63.6254 68.3915 64 67.45 64H3.55C2.60848 64 1.70552 63.6254 1.03977 62.9586C0.374017 62.2918 0 61.3874 0 60.4444V32H7.1V56.8889Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </label>
                            <input
                                id="candidate-attachment"
                                type="file"
                                onChange={onChange}
                                className="hidden"
                                multiple="false"
                                // accept=".csv"
                            />
                            <span className="font-bold">
                                <span className="text-primary-800">Seleziona file</span> o trascina
                                qui
                            </span>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal Footer */}
            {/* {files.length > 0 ? ( */}
            <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                <PrimaryButton onClick={onSubmitHandler}>
                    {selectedFile ? 'MODIFICA' : 'AGGIUNGI'}
                </PrimaryButton>
            </div>
            {/* ) : null} */}
        </ModalComponent>
    );
}

export default AddAttachment;
