/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContainer from '../component/AuthContainer';
import PrimaryButton from '../component/PrimaryButton';

import { authLogin } from '../helper/services/apis/auth-services';

import { EMAIL_REGEX, MESSAGES } from '../constants';
import Storage from '../helper/services/common/storage-helper-services';

function Login() {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (loginData) => {
        const result = await authLogin(loginData).catch((e) =>
            toast.error(MESSAGES.INVALID_CREDENTIAL)
        );
        if (result && result.ok) {
            Storage.set('auth-token', result.data.token);
            Storage.set(
                'profile',
                JSON.stringify({
                    id: result.data.user.id,
                    userName: result.data.user.fullName,
                    email: result.data.user.email,
                    role: result.data.user.role.slug,
                    firstTimeLogin: result.data.user.firstTimeLogin
                })
            );
            Storage.set('permission', JSON.stringify(result.data.permissions));
            if (result.data.user.firstTimeLogin) {
                navigate(`../update-password/${result.data.user.id}`);
            } else {
                navigate(`../dashboard`);
            }
        }
    };

    /**
     * if viste login page clear storage.
     */
    useEffect(() => {
        Storage.clear();
    }, []);

    return (
        <section className="flex w-screen h-screen justify-center items-center bg-lightBlue-700">
            <AuthContainer>
                <form className="mt-12 space-y-5" onSubmit={handleSubmit(onSubmitHandler)}>
                    <p className="app-label">Accedi alla tua area privata</p>
                    <div className="input-field-group">
                        <label htmlFor="email" className="block mb-2 text-sm font-bold">
                            Mail
                        </label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Mail"
                            {...register('email', { required: true, pattern: EMAIL_REGEX })}
                        />
                        {errors?.email?.type === 'pattern' && (
                            <p className="">{MESSAGES.INVALID_EMAIL}</p>
                        )}
                    </div>

                    <div className="input-field-group">
                        <label htmlFor="password" className="block mb-2 text-sm font-bold">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            {...register('password', { required: true })}
                        />
                        <p className="text-right">
                            <Link to="/forgot-password" className="app-link">
                                Hai perso la password? Clicca qui?
                            </Link>
                        </p>
                    </div>

                    <div className="text-center">
                        <PrimaryButton type="submit" className="mx-auto">
                            ACCEDI
                        </PrimaryButton>
                    </div>
                </form>
            </AuthContainer>
        </section>
    );
}

export default Login;
