/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrashCan, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import DataTable from './DataTable';
import { CANDIDATE_ATTACHMENT_COLUMNS, MESSAGES } from '../constants';
import AddAttachment from './modal/AddAttachment';
import ConfirmationModal from './modal/ConfirmationModal';
import {
    deleteCandidateAttachment,
    downloadAttachment
} from '../helper/services/apis/candidate-services';
import Loader from './Loader';

function CandidateAttachments({
    candidate = null,
    isAddAttachmentOpen = false,
    updateAttachmentModalState = null,
    reload = null
}) {
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const onCloseAddAttachmentModal = (isReload = false) => {
        setSelectedAttachment(null);
        updateAttachmentModalState(false, isReload);
    };

    const downloadFile = async (attachment) => {
        const result = await downloadAttachment({ filePath: attachment.path }).catch((e) =>
            toast.error(e.message)
        );
        const downloadLink = document.createElement('a');
        downloadLink.target = 'blank';
        downloadLink.download = `${attachment.name}`;
        downloadLink.href = result.data;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const editAttachment = (attachment) => {
        setSelectedAttachment({ ...attachment });
        updateAttachmentModalState(true, false);
    };

    const deleteAttachment = (attachment) => {
        setSelectedAttachment({ ...attachment });
        setConfirmDeleteModalOpen(true);
        // updateCreateActivityModalState(true, false);
    };

    const closeConfirmModalHandler = () => {
        setSelectedAttachment(null);
        setConfirmDeleteModalOpen(false);
    };

    const confirmDeleteAttachment = async () => {
        setIsLoading(true);
        const result = await deleteCandidateAttachment(selectedAttachment.id).catch((e) => {
            console.log(e);
            setIsLoading(false);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            setIsLoading(false);
            closeConfirmModalHandler();
            reload();
        }
    };

    const getActionTemplate = (item) => {
        const template = (
            <div className="flex justify-between text-lg">
                <FontAwesomeIcon
                    icon={faDownload}
                    className="text-primary-500 cursor-pointer"
                    onClick={() => downloadFile(item)}
                />
                {!candidate?.isArchive && (
                    <>
                        <FontAwesomeIcon
                            icon={faUpload}
                            className="text-primary-500 cursor-pointer"
                            onClick={() => editAttachment(item)}
                        />
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className="text-red-500 cursor-pointer"
                            onClick={() => deleteAttachment(item)}
                        />
                    </>
                )}
            </div>
        );
        return template;
    };

    const getRows = (data = []) => {
        return data.map((item) => ({
            className: '',
            cells: [
                { template: item.name, className: 'min-w-[128px]' },
                { template: item.description, className: 'min-w-[128px]' },
                { template: item.createdAt, className: 'min-w-[128px]' },
                { template: getActionTemplate(item), className: 'min-w-[128px]' }
            ]
        }));
    };
    return (
        <section className="py-2">
            {isLoading ? <Loader /> : null}
            {confirmDeleteModalOpen ? (
                <ConfirmationModal
                    isOpen={confirmDeleteModalOpen}
                    title="Elimina allegato"
                    message="Vuoi eliminare l’allegato selezionato?"
                    onConfirmAction={confirmDeleteAttachment}
                    closeModel={closeConfirmModalHandler}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            {isAddAttachmentOpen ? (
                <AddAttachment
                    isOpen={isAddAttachmentOpen}
                    candidateId={candidate.id}
                    selectedFile={selectedAttachment}
                    closeModel={onCloseAddAttachmentModal}
                />
            ) : null}

            <DataTable
                columns={CANDIDATE_ATTACHMENT_COLUMNS}
                rows={getRows(candidate.attachments)}
            />
        </section>
    );
}

export default CandidateAttachments;
