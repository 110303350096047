/* eslint-disable react/prop-types */
import React from 'react';
import Logo from '../assets/images/logo.png';

function AuthContainer({ children, className }) {
    return (
        <div
            className={`flex justify-center items-center relative w-[768px] h-[469px] z-0 ${className}`}>
            {/* <div className="w-full h-full absolute top-0 left-0 bg-primary-800 md:rotate-[30deg]" /> */}
            <div className="w-full h-full absolute top-0 left-0 bg-white rounded-xl shadow-xl" />
            <div className="z-10 flex flex-col justify-center items-center w-3/4 md:w-3/6">
                <img src={Logo} alt="logo" className="w-20" />
                <div className="w-full">{children}</div>
            </div>
        </div>
    );
}

export default AuthContainer;
