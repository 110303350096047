/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ACTIVITY_STATUS_LIST } from '../../constants';
import {
    getCandidatesStatusDropdown,
    getManagersDropdown
} from '../../helper/services/apis/common-services';
import { getTableData } from '../../helper/services/apis/database-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function CandidateListingFilters({ isOpen, closeModel, filters, handleFilterChange }) {
    const [mngManagers, setMngManagers] = useState([]);
    const [managers, setManagers] = useState([]);
    const [banks, setBanks] = useState([]);
    const [branches, setBranches] = useState([]);
    const days = [
        { value: 'green', label: 'Da 0 a 10 giorni' },
        { value: 'yellow', label: 'Da 11 a 20 giorni' },
        { value: 'red', label: 'oltre 20' }
    ];
    const activityStatuses = [...ACTIVITY_STATUS_LIST];
    const [candidateStatuses, setCandidateStatuses] = useState([...ACTIVITY_STATUS_LIST]);

    const iniMngManagersForFilterDropDown = async () => {
        // getManagersDropdown(onlyMng, mng, candidateId, subManager).catch((e) =>
        const result = await getManagersDropdown(true, null, null, null).catch((e) =>
            console.log(e.message)
        );

        if (result.data) {
            setMngManagers(result.data.map((item) => ({ value: item.id, label: item.fullName })));
        }
    };

    const iniManagersForFilterDropDown = async () => {
        // getManagersDropdown(onlyMng, mng, candidateId, subManager).catch((e) =>
        const result = await getManagersDropdown(null, null, null, true).catch((e) =>
            console.log(e.message)
        );

        if (result.data) {
            setManagers(result.data.map((item) => ({ value: item.id, label: item.fullName })));
        }
    };

    const initBanksForFilterDropDown = async () => {
        const result = await getTableData('banks').catch((e) => console.log(e.message));

        if (result.data) {
            setBanks(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const initBranchesForFilterDropDown = async () => {
        const result = await getTableData('branches').catch((e) => console.log(e.message));

        if (result.data) {
            setBranches(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const initCandidateStatusForFilterDropDown = async () => {
        const result = await getCandidatesStatusDropdown().catch((e) => console.log(e.message));

        if (result.data) {
            setCandidateStatuses(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    useEffect(() => {
        iniMngManagersForFilterDropDown();
        iniManagersForFilterDropDown();
        initBanksForFilterDropDown();
        initBranchesForFilterDropDown();
        initCandidateStatusForFilterDropDown();
    }, []);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel()}
            title="Filtri"
            size="max-w-[632px]">
            {/* Modal Body */}
            <div className="p-6 space-y-6">
                <div className="flex flex-col w-full space-y-4">
                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">MNG Crescita : </label>
                        <Select
                            value={[...filters.mngManagers]}
                            isMulti
                            onChange={(e) => handleFilterChange('mngManagers', e, filters)}
                            options={mngManagers}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="MNG Crescita"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Manager : </label>
                        <Select
                            value={[...filters.managers]}
                            isMulti
                            onChange={(e) => handleFilterChange('managers', e, filters)}
                            options={managers}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Manager"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Banca : </label>
                        <Select
                            value={[...filters.banks]}
                            isMulti
                            onChange={(e) => handleFilterChange('banks', e, filters)}
                            options={banks}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Banca"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Comune : </label>
                        <Select
                            value={[...filters.branches]}
                            isMulti
                            onChange={(e) => handleFilterChange('branches', e, filters)}
                            options={branches}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Comune"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Giorni in step : </label>
                        <Select
                            value={[...filters.days]}
                            isMulti
                            onChange={(e) => handleFilterChange('days', e, filters)}
                            options={days}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Giorni in step"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Stato azioni : </label>
                        <Select
                            value={[...filters.activityStatuses]}
                            isMulti
                            onChange={(e) => handleFilterChange('activityStatuses', e, filters)}
                            options={activityStatuses}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Stato azioni"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Stato : </label>
                        <Select
                            value={[...filters.candidateStatuses]}
                            isMulti
                            onChange={(e) => handleFilterChange('candidateStatuses', e, filters)}
                            options={candidateStatuses}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Stato"
                        />
                    </div>

                    <div className="w-full flex space-x-2">
                        <div className="w-1/2 space-y-2 input-field-group">
                            <label htmlFor="name">Da : </label>
                            <input
                                type="date"
                                value={filters.startDate}
                                placeholder="Da"
                                onChange={(e) =>
                                    handleFilterChange('startDate', e.target.value, filters)
                                }
                            />
                        </div>
                        <div className="w-1/2 space-y-2 input-field-group">
                            <label htmlFor="name">A : </label>
                            <input
                                type="date"
                                value={filters.endDate}
                                placeholder="A"
                                onChange={(e) =>
                                    handleFilterChange('endDate', e.target.value, filters)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Footer */}
            <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                <SecondaryButton onClick={() => closeModel(true)}>Rimuovi tutti</SecondaryButton>
                <PrimaryButton onClick={() => closeModel()}>Applica</PrimaryButton>
            </div>
        </ModalComponent>
    );
}

export default CandidateListingFilters;
