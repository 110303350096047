/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MESSAGES } from '../../constants';
import { updateCandidateGeneralInfo } from '../../helper/services/apis/candidate-services';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import GeneralTablesDropdown from '../dropdown/GeneralTablesDropdown';
import ModalComponent from './ModalComponent';

function UpdateGeneralInfo({ isOpen = true, closeModel, candidate = null, isProfessional = true }) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitHandler = async (formData) => {
        setIsLoading(true);
        if (candidate?.generalInfo?.id) {
            const result = await updateCandidateGeneralInfo(candidate.generalInfo.id, {
                ...formData
            }).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel(true);
            }
        }
        setIsLoading(false);
    };

    const setUserFormData = (data) => {
        if (data) {
            reset({ ...data });
        }
    };

    useEffect(() => {
        if (candidate.generalInfo) {
            setUserFormData(candidate.generalInfo);
        }
    }, [candidate]);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel(false)}
            title={isProfessional ? 'Informazioni Professionali' : 'Informazioni Personali'}
            size={isProfessional ? 'max-w-5xl' : 'max-w-[632px]'}>
            {isLoading ? <Loader /> : null}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        {isProfessional ? (
                            <>
                                <div className="flex w-full space-x-4">
                                    <GeneralTablesDropdown
                                        register={register}
                                        type="banks"
                                        name="bankId"
                                        label="Banca"
                                        errors={errors}
                                        setValue={setValue}
                                        className="w-1/2"
                                    />
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.classification ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="classification">Inquadramento : </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="classification"
                                                name="classification"
                                                placeholder="Inquadramento"
                                                {...register('classification', {
                                                    maxLength: 255
                                                })}
                                            />
                                        </div>
                                        {errors?.classification?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="flex w-full space-x-4">
                                    <GeneralTablesDropdown
                                        register={register}
                                        type="branches"
                                        name="commonBranchId"
                                        label="Comune filiale"
                                        errors={errors}
                                        setValue={setValue}
                                        className="w-1/2"
                                    />
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.lastCu ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="lastCu">Ultima CU :</label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="lastCu"
                                                name="lastCu"
                                                placeholder="Ultima CU"
                                                {...register('lastCu', {
                                                    maxLength: 255
                                                })}
                                            />
                                        </div>
                                        {errors?.lastCu?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="flex w-full space-x-4">
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.branchAddress ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="branchAddress">
                                                Indirizzo filiale :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="branchAddress"
                                                name="branchAddress"
                                                placeholder="Indirizzo filiale"
                                                {...register('branchAddress', {
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.branchAddress?.type === 'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage()}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.ocfRegister ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="ocfRegister">
                                                Iscrizione albo OCF :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="ocfRegister"
                                                name="ocfRegister"
                                                placeholder="Iscrizione albo OCF"
                                                {...register('ocfRegister', {
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.ocfRegister?.type === 'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage()}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full space-x-4">
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.yearsWorkedInBank ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="yearsWorkedInBank">
                                                Da quanti anni in banca :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="yearsWorkedInBank"
                                                name="yearsWorkedInBank"
                                                placeholder="Da quanti anni in banca"
                                                {...register('yearsWorkedInBank', {
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.yearsWorkedInBank?.type === 'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage()}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.stabilityPact ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="stabilityPact">
                                                Patto di stabilità :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="stabilityPact"
                                                name="stabilityPact"
                                                placeholder="Patto di stabilità"
                                                {...register('stabilityPact', {
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.stabilityPact?.type === 'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage()}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full space-x-4">
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.yearsWorkedInLastBranch ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="yearsWorkedInLastBranch">
                                                Da quanti anni nell’ultima filiale :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="yearsWorkedInLastBranch"
                                                name="yearsWorkedInLastBranch"
                                                placeholder="Da quanti anni nell’ultima filiale"
                                                {...register('yearsWorkedInLastBranch', {
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.yearsWorkedInLastBranch?.type ===
                                                'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage()}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.nonCompetitionAgreement ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="nonCompetitionAgreement">
                                                Patto di non concorrenza :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="nonCompetitionAgreement"
                                                name="nonCompetitionAgreement"
                                                placeholder="Patto di non concorrenza"
                                                {...register('nonCompetitionAgreement', {
                                                    maxLength: 255
                                                })}
                                            />
                                            {errors?.nonCompetitionAgreement?.type ===
                                                'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage()}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full space-x-4">
                                    <GeneralTablesDropdown
                                        register={register}
                                        type="prof-roles"
                                        name="professionalRoleId"
                                        label="Ruolo professionale"
                                        errors={errors}
                                        setValue={setValue}
                                        className="w-1/2"
                                    />
                                    <div
                                        className={`w-1/2 flex items-center space-x-2 input-field-group ${
                                            errors?.customerPortfolio ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="customerPortfolio">
                                                Portafoglio clienti :
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="text"
                                                id="customerPortfolio"
                                                name="customerPortfolio"
                                                placeholder="Portafoglio clienti"
                                                {...register('customerPortfolio', {
                                                    maxLength: 255
                                                })}
                                            />
                                        </div>
                                        {errors?.customerPortfolio?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.professionalNote ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/6">
                                        <label htmlFor="professionalNote">Note : </label>
                                    </div>
                                    <div className="w-5/6">
                                        <textarea
                                            rows="4"
                                            id="professionalNote"
                                            name="professionalNote"
                                            placeholder="Note"
                                            {...register('professionalNote', {
                                                maxLength: 1000
                                            })}
                                        />
                                        {errors?.professionalNote?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage(1000)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.dob ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="dob">Data di nascita : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="date"
                                            id="dob"
                                            name="dob"
                                            {...register('dob')}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.conjugated ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="conjugated">Coniugato : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="text"
                                            id="conjugated"
                                            name="conjugated"
                                            placeholder="Coniugato"
                                            {...register('conjugated', {
                                                maxLength: 255
                                            })}
                                        />
                                        {errors?.conjugated?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.spouseActivity ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="spouseActivity">Attività coniuge : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="text"
                                            id="spouseActivity"
                                            name="spouseActivity"
                                            placeholder="Attività coniuge"
                                            {...register('spouseActivity', {
                                                maxLength: 255
                                            })}
                                        />
                                        {errors?.spouseActivity?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.numberOfChild ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="numberOfChild">N° figli : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="text"
                                            id="numberOfChild"
                                            name="numberOfChild"
                                            placeholder="N° figli"
                                            {...register('numberOfChild', {
                                                maxLength: 255
                                            })}
                                        />
                                        {errors?.numberOfChild?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.personalNote ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="personalNote">Note : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <textarea
                                            rows="4"
                                            id="personalNote"
                                            name="personalNote"
                                            placeholder="Note"
                                            {...register('personalNote', {
                                                maxLength: 1000
                                            })}
                                        />
                                        {errors?.personalNote?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage(1000)}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                    <SecondaryButton onClick={() => closeModel(false)}>ANNULLA</SecondaryButton>
                    <PrimaryButton type="submit">CONFERMA</PrimaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default UpdateGeneralInfo;
