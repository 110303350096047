/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EMAIL_REGEX, INIT_CANDIDATE, MESSAGES } from '../../constants';
import { createNewCandidate, updateCandidate } from '../../helper/services/apis/candidate-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
// import CandidateStatusDropdown from '../dropdown/CandidateStatusDropdown';
import ManagersDropdown from '../dropdown/ManagersDropdown';
import ModalComponent from './ModalComponent';

function CreateCandidate({ isOpen, closeModel, selectedCandidate = null }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (formData) => {
        if (selectedCandidate) {
            const result = await updateCandidate(selectedCandidate.id, formData).catch((e) => {
                if (e.message === 'Record Exists') {
                    toast.error(
                        `Il candidato è già presente nel sistema ed è assegnato al manager : (${e.errors.manager})`
                    );
                } else if (e.message && e.message.phoneNumber) {
                    toast.error(
                        'Il numero di telefono inserito è già stato associato ad un altro candidato.'
                    );
                } else {
                    toast.error(MESSAGES.SERVER_ERROR);
                }
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            }
        } else {
            const result = await createNewCandidate(formData).catch((e) => {
                if (e.message === 'Record Exists') {
                    toast.error(
                        `Il candidato è già presente nel sistema ed è assegnato al manager : (${e.errors.manager})`
                    );
                } else if (e.message && e.message.phoneNumber) {
                    toast.error(
                        'Il numero di telefono inserito è già stato associato ad un altro candidato.'
                    );
                } else {
                    toast.error(MESSAGES.SERVER_ERROR);
                }
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                closeModel(true);
            }
        }
    };

    const setUserFormData = (data) => {
        if (data) {
            reset({ ...selectedCandidate });
        } else {
            reset({ ...INIT_CANDIDATE });
        }
    };

    useEffect(() => {
        if (selectedCandidate) {
            setUserFormData(selectedCandidate);
        } else {
            setUserFormData(null);
        }
    }, [selectedCandidate]);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={closeModel}
            title={selectedCandidate ? 'Modifica candidato' : 'Aggiungi Candidato'}
            size="max-w-[632px]">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.name || errors?.surname ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/3">
                                <label htmlFor="name">Nome e cognome* : </label>
                            </div>
                            <div className="w-2/3 flex justify-between">
                                <div>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Nome"
                                        {...register('name', { required: true, maxLength: 50 })}
                                    />
                                    {errors?.name?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                    {errors?.name?.type === 'maxLength' && (
                                        <p className="text-red-700">
                                            {MESSAGES.maxLimitMessage(50)}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        id="surname"
                                        name="surname"
                                        placeholder="Cognome"
                                        {...register('surname', { required: true, maxLength: 50 })}
                                    />
                                    {errors?.surname?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                    {errors?.surname?.type === 'maxLength' && (
                                        <p className="text-red-700">
                                            {MESSAGES.maxLimitMessage(50)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {selectedCandidate ? (
                            <div
                                className={`w-full flex items-center space-x-2 input-field-group ${
                                    errors?.address ? 'invalid-field' : ''
                                }`}>
                                <div className="w-1/3">
                                    <label htmlFor="address">Indirizzo : </label>
                                </div>
                                <div className="w-2/3">
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        placeholder="Indirizzo"
                                        {...register('address', { maxLength: 500 })}
                                    />
                                    {errors?.address?.type === 'maxLength' && (
                                        <p className="text-red-700">
                                            {MESSAGES.maxLimitMessage(500)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <ManagersDropdown
                                register={register}
                                name="managerId"
                                errors={errors}
                                config={{ required: true }}
                                label="Assegnato a"
                                subManager
                            />
                        )}

                        <div
                            className={`w-full flex items-center space-x-2 input-field-group ${
                                errors?.phoneNumber ? 'invalid-field' : ''
                            }`}>
                            <div className="w-1/3">
                                <label htmlFor="phoneNumber">N° Telefono* : </label>
                            </div>
                            <div className="w-2/3">
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder="Telefono"
                                    {...register('phoneNumber', { required: true, maxLength: 20 })}
                                />
                                {errors?.phoneNumber?.type === 'required' && (
                                    <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                )}
                                {errors?.phoneNumber?.type === 'maxLength' && (
                                    <p className="text-red-700">{MESSAGES.maxLimitMessage(20)}</p>
                                )}
                            </div>
                        </div>

                        <ManagersDropdown
                            register={register}
                            name="mngManagerId"
                            errors={errors}
                            config={{ required: true }}
                            label="MNG Crescita"
                            onlyMng
                        />

                        {selectedCandidate ? (
                            <>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.email ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="email">Mail* : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="email"
                                            {...register('email', {
                                                required: false,
                                                maxLength: 255,
                                                pattern: EMAIL_REGEX
                                            })}
                                        />
                                        {errors?.email?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                        {errors?.email?.type === 'pattern' && (
                                            <p className="text-red-700">{MESSAGES.INVALID_EMAIL}</p>
                                        )}
                                    </div>
                                </div>
                                {/* <CandidateStatusDropdown
                                    register={register}
                                    name="statusId"
                                    errors={errors}
                                    config={{ required: true }}
                                    label="Stato"
                                /> */}
                            </>
                        ) : null}
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                    <SecondaryButton onClick={closeModel}>ANNULLA</SecondaryButton>
                    <PrimaryButton type="submit">CONFERMA</PrimaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateCandidate;
