/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContainer from '../component/AuthContainer';
import PrimaryButton from '../component/PrimaryButton';

import { authLogin } from '../helper/services/apis/auth-services';

import { EMAIL_REGEX, MESSAGES } from '../constants';
import Storage from '../helper/services/common/storage-helper-services';
import { getUserById, updateUserPassword } from '../helper/services/apis/user-services';

function UpdatePassword() {
    const navigate = useNavigate();
    const params = useParams();
    const [user, setUser] = useState(null);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (formData) => {
        const result = await updateUserPassword(user.id, {
            ...formData
        }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            console.log(result);
            toast.success(MESSAGES.SUCCESS);
            navigate(`/login`);
        }
    };

    useEffect(() => {
        let data = Storage.get('profile');
        data = JSON.parse(data);
        if (!data.firstTimeLogin) {
            navigate(`/dashboard`);
        }
        setUser(data);
    }, [params.id]);

    return (
        <section className="flex w-screen h-screen justify-center items-center bg-lightBlue-700">
            <AuthContainer className="h-[516px]">
                <form className="mt-12 space-y-5" onSubmit={handleSubmit(onSubmitHandler)}>
                    <p className="app-label">AGGIORNA PASSWORD</p>
                    <div className="w-full input-field-group">
                        <label htmlFor="currentPassword">Password corrente* : </label>
                        <input
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            placeholder="Password corrente"
                            {...register('currentPassword', {
                                required: true,
                                maxLength: 50
                            })}
                        />
                        {errors?.currentPassword?.type === 'required' && (
                            <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                        )}
                    </div>
                    <div className="w-full input-field-group">
                        <label htmlFor="newPassword">Nuova password* : </label>
                        <input
                            type="text"
                            id="newPassword"
                            name="newPassword"
                            placeholder="Nuova password"
                            {...register('newPassword', {
                                required: true,
                                maxLength: 50
                            })}
                        />
                        {errors?.newPassword?.type === 'required' && (
                            <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                        )}
                    </div>
                    <div className="w-full input-field-group">
                        <label htmlFor="compareNewPassword">Conferma nuova password* : </label>
                        <input
                            type="text"
                            id="compareNewPassword"
                            name="compareNewPassword"
                            placeholder="Conferma nuova password"
                            {...register('compareNewPassword', {
                                required: true,
                                maxLength: 50,
                                validate: (val) => {
                                    if (watch('newPassword') !== val) {
                                        return 'Your passwords do no match';
                                    }

                                    return null;
                                }
                            })}
                        />
                        {errors?.compareNewPassword?.type === 'required' && (
                            <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                        )}
                        {errors?.compareNewPassword?.type === 'validate' && (
                            <p className="text-red-700">{errors?.compareNewPassword?.message}</p>
                        )}
                    </div>

                    <div className="text-center">
                        <PrimaryButton type="submit" className="mx-auto">
                            AGGIORNA
                        </PrimaryButton>
                    </div>
                </form>
            </AuthContainer>
        </section>
    );
}

export default UpdatePassword;
