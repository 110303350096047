/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';

export const getUsersListData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.USER}s?${queryString}`);
    return result;
};

export const getUsersRevenueData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.USER}s/revenue?${queryString}`);
    return result;
};

export const getUsersMngData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.USER}s/mng?${queryString}`);
    return result;
};

export const getUserById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.USER}/${id}`);
    return result;
};

export const getEditUserById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.USER}-edit/${id}`);
    return result;
};

export const getUsersRolesDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.ROLE}s-dropdown`);
    return result;
};

export const createNewUser = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.USER}`, payload);
    return result;
};

export const updateUserGoals = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.USER}-goals/${id}`, payload);
    return result;
};

export const updateUser = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.USER}/${id}`, payload);
    return result;
};

export const updateUserPassword = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.USER}-password/${id}`, payload);
    return result;
};

export const updateUserField = async (id, payload, mode) => {
    let path = `v1/${API_ENDPOINT.USER}`;
    if (mode === 'status') {
        path += '/status';
    }

    const result = await axiosInstance.put(`${path}/${id}`, payload);
    return result;
};

export const deleteUser = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.USER}/${id}`);
    return result;
};

export const userSendNewPassword = async (payload) => {
    const result = await axiosInstance.post(`v1/user-send-new-password`, payload);
    return result;
};

export const getSharingManager = async (id, qs) => {
    const result = await axiosInstance.get(`v1/sharing-managers/${id}?${qs}`);
    return result;
};

export const getManagerRevenueData = async (id, qs) => {
    const result = await axiosInstance.get(`v1/manager-revenue/${id}?${qs}`);
    return result;
};
