/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EMAIL_REGEX, INIT_USER, MESSAGES } from '../../constants';
import { getRolesDropdown } from '../../helper/services/apis/common-services';
import {
    createNewUser,
    getEditUserById,
    updateUser,
    updateUserGoals
} from '../../helper/services/apis/user-services';
import { getCurrentYear } from '../../helper/utility';
import Loader from '../Loader';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ManagersDropdown from '../dropdown/ManagersDropdown';
import RolesDropdown from '../dropdown/RolesDropdown';
import YearDropdown from '../dropdown/YearDropdown';
import ModalComponent from './ModalComponent';

function CreateManager({
    isOpen,
    closeModel,
    selectedUserId = null,
    isCreateNew = false,
    isUpdateInfo = false,
    isUpdateGoal = false,
    isProfile = false
}) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm();
    const selectedYear = watch('selectedYear');
    const [isLoading, setIsLoading] = useState(false);
    const [goals, setGoals] = useState([]);
    const [assistantManagerId, setAssistantManagerId] = useState(null);
    const [secretaryId, setSecretaryId] = useState(null);
    const [assistantManagers, setAssistantManagers] = useState([]);

    const onSubmitHandler = async (formData) => {
        if ((formData.usefulWeeks || formData.goals) && !formData.selectedYear) {
            return toast.error(`Seleziona l'anno`);
        }
        const test = Array.isArray(formData.managerId);
        if (test) {
            formData.managerId = formData.managerId.map((item) => item.value);
        }
        setIsLoading(true);
        if (selectedUserId) {
            if (isUpdateInfo) {
                const result = await updateUser(formData.id, formData).catch((e) => {
                    console.log(e);
                    toast.error(MESSAGES.SERVER_ERROR);
                    setIsLoading(false);
                });
                if (result.ok) {
                    toast.success(MESSAGES.SUCCESS);
                    setIsLoading(false);
                    closeModel(true);
                }
            } else if (isUpdateGoal) {
                const result = await updateUserGoals(formData.id, formData).catch((e) => {
                    console.log(e);
                    toast.error(MESSAGES.SERVER_ERROR);
                    setIsLoading(false);
                });
                if (result.ok) {
                    toast.success(MESSAGES.SUCCESS);
                    setIsLoading(false);
                    closeModel(true);
                }
            }
        } else {
            const result = await createNewUser(formData).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                setIsLoading(false);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                setIsLoading(false);
                closeModel(true);
            }
        }
    };

    const setUserFormData = (data) => {
        if (data) {
            reset({
                id: data.id,
                name: data.name,
                surname: data.surname,
                roleId: data.roleId,
                email: data.email,
                isManagerRole: data.isManagerRole,
                phoneNumber: data.phoneNumber,
                address: data.address,
                managerId: data.managerId
            });
        } else {
            reset({ ...INIT_USER });
        }
    };

    const retrieveManagerData = async (id) => {
        setIsLoading(true);

        const result = await getEditUserById(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            setIsLoading(false);
        });
        if (result.ok) {
            if (isUpdateInfo) {
                const records = result?.data?.assistantManagerAssignManagers?.map((item) => ({
                    value: item.id,
                    label: item.fullName
                }));
                if (records && Array.isArray(records) && records.length) {
                    setUserFormData({ ...result.data, managerId: [...records] });
                    setAssistantManagers([...records]);
                } else {
                    setUserFormData({ ...result.data });
                    setAssistantManagers([]);
                }
            } else if (isUpdateGoal) {
                const year = getCurrentYear();
                setGoals(result.data.goals);
                reset({
                    id: result.data.id,
                    goals: 0,
                    selectedYear: year
                });
            }
        }
        setIsLoading(false);
    };

    const setGoalsForSelectedYear = (y, data) => {
        if (data.length) {
            const yearData = data.find((item) => item.year == y);
            if (yearData) {
                setValue('goals', yearData.goals);
            } else {
                setValue('goals', 0);
            }
        }
    };

    const initRoles = async () => {
        const result = await getRolesDropdown().catch((e) => console.log(e.message));
        const astId = result?.data?.find((item) => item.name == 'Assistente Manager');
        const secId = result?.data?.find((item) => item.name == 'Assistente HR Region');
        if (astId) {
            setAssistantManagerId(astId.id);
        }
        if (secId) {
            setSecretaryId(secId.id);
        }
    };

    useEffect(() => {
        if (isUpdateGoal) {
            setGoalsForSelectedYear(selectedYear, goals);
        }
    }, [selectedYear]);

    useEffect(() => {
        initRoles();
        if (selectedUserId) {
            retrieveManagerData(selectedUserId);
        } else {
            setUserFormData(null);
        }
    }, [selectedUserId]);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel(false)}
            title={
                isUpdateGoal
                    ? 'Modifica obiettivo'
                    : isUpdateInfo
                    ? 'Modifica Manager'
                    : 'Aggiungi Manager'
            }
            size="max-w-[632px]">
            {isLoading ? <Loader /> : null}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* Modal Body */}
                <div className="p-6 space-y-6">
                    <div className="flex flex-col w-full space-y-4">
                        {(isCreateNew || isUpdateInfo) && (
                            <>
                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.name || errors?.surname ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="name">Nome e cognome* : </label>
                                    </div>
                                    <div className="w-2/3 flex justify-between">
                                        <div>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Nome"
                                                {...register('name', {
                                                    required: true,
                                                    maxLength: 50
                                                })}
                                            />
                                            {errors?.name?.type === 'required' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.FIELD_REQUIRED}
                                                </p>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                id="surname"
                                                name="surname"
                                                placeholder="Cognome"
                                                {...register('surname', {
                                                    required: true,
                                                    maxLength: 50
                                                })}
                                            />
                                            {errors?.surname?.type === 'required' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.FIELD_REQUIRED}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!isProfile && (
                                    <RolesDropdown
                                        register={register}
                                        name="roleId"
                                        errors={errors}
                                        config={{ required: true }}
                                    />
                                )}

                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.email ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="email">Mail* : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="email"
                                            {...register('email', {
                                                required: true,
                                                maxLength: 255,
                                                pattern: EMAIL_REGEX
                                            })}
                                        />
                                        {errors?.email?.type === 'required' && (
                                            <p className="text-red-700">
                                                {MESSAGES.FIELD_REQUIRED}
                                            </p>
                                        )}
                                        {errors?.email?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                        {errors?.email?.type === 'pattern' && (
                                            <p className="text-red-700">{MESSAGES.INVALID_EMAIL}</p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.email ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="isManagerRole">MNG Crescita : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <select
                                            id="isManagerRole"
                                            {...register('isManagerRole', {
                                                required: false
                                            })}
                                            disabled={
                                                secretaryId == watch('roleId') ||
                                                assistantManagerId == watch('roleId')
                                            }>
                                            <option value="0">No</option>
                                            <option value="1">Si</option>
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.phoneNumber ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="phoneNumber">Telefono : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder="Telefono"
                                            {...register('phoneNumber', { maxLength: 20 })}
                                        />
                                        {errors?.phoneNumber?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage(20)}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`w-full flex items-center space-x-2 input-field-group ${
                                        errors?.address ? 'invalid-field' : ''
                                    }`}>
                                    <div className="w-1/3">
                                        <label htmlFor="address">Indirizzo : </label>
                                    </div>
                                    <div className="w-2/3">
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            placeholder="Indirizzo"
                                            {...register('address', { maxLength: 255 })}
                                        />
                                        {errors?.address?.type === 'maxLength' && (
                                            <p className="text-red-700">
                                                {MESSAGES.maxLimitMessage()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {!isProfile && assistantManagerId != watch('roleId') ? (
                                    <ManagersDropdown
                                        register={register}
                                        name="managerId"
                                        errors={errors}
                                        config={{ required: true }}
                                        roleType={watch('roleId')}
                                        label="Assegnato a"
                                    />
                                ) : null}
                                {!isProfile && assistantManagerId == watch('roleId') ? (
                                    <ManagersDropdown
                                        register={register}
                                        name="managerId"
                                        errors={errors}
                                        config={{ required: true }}
                                        roleType={watch('roleId')}
                                        label="Assegnato a"
                                        defaultValue={[...assistantManagers]}
                                        setValue={setValue}
                                        onChangeValue={(e) => setAssistantManagers(e)}
                                        multiple
                                    />
                                ) : null}
                            </>
                        )}
                        {secretaryId !== watch('roleId') &&
                            assistantManagerId !== watch('roleId') &&
                            (isCreateNew || isUpdateGoal) && (
                                <>
                                    <div
                                        className={`w-full flex items-center space-x-2 input-field-group ${
                                            errors?.goals ? 'invalid-field' : ''
                                        }`}>
                                        <div className="w-1/3">
                                            <label htmlFor="goals">
                                                {' '}
                                                Obiettivo ins. personale :{' '}
                                            </label>
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                type="number"
                                                id="goals"
                                                name="goals"
                                                placeholder="Obiettivo ins. personale"
                                                {...register('goals', { maxLength: 5 })}
                                            />
                                            {errors?.goals?.type === 'maxLength' && (
                                                <p className="text-red-700">
                                                    {MESSAGES.maxLimitMessage(5)}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <YearDropdown
                                        name="selectedYear"
                                        register={register}
                                        label="Periodo obiettivo"
                                        errors={errors}
                                    />
                                </>
                            )}
                    </div>
                </div>

                {/* Modal Footer */}
                <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                    <SecondaryButton onClick={() => closeModel(false)}>ANNULLA</SecondaryButton>
                    <PrimaryButton type="submit">CONFERMA</PrimaryButton>
                </div>
            </form>
        </ModalComponent>
    );
}

export default CreateManager;
