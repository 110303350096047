/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-key */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { faCheck, faMagnifyingGlass, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import SearchField from '../../component/SearchField';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import CreateManager from '../../component/modal/CreateManager';
import {
    MESSAGES,
    USERS_MNG_TABLE_COLUMNS,
    USERS_REVENUE_TABLE_COLUMNS,
    USERS_REVENUE_TABLE_ONLY_PROG_COLUMNS,
    USERS_TABLE_COLUMNS
} from '../../constants';
import { getRolesDropdown } from '../../helper/services/apis/common-services';
import {
    deleteUser,
    getManagerRevenueData,
    getUsersListData,
    getUsersMngData,
    getUsersRevenueData,
    updateUserField
} from '../../helper/services/apis/user-services';
import {
    buildQueryChunk,
    checkPagePermission,
    generateAndDownloadCSV,
    getCurrentYear
} from '../../helper/utility';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import ModalComponent from '../../component/modal/ModalComponent';

function Managers() {
    const navigate = useNavigate();
    const currentYear = getCurrentYear();
    const [revenueColumns, setRevenueColumns] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [managersData, setManagersData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [years, setYears] = useState([]);
    const [managerRevenueData, setManagerRevenueData] = useState([]);
    const [managerRevenueSearchString, setManagerRevenueSearchString] = useState('');

    const [filters, setFilters] = useState({
        year: currentYear
    });

    const [frontFilters, setFrontFilters] = useState({
        searchString: '',
        // startDate: '',
        // endDate: '',
        role: ''
    });

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isUserCandidateOpen: false,
        isConfirmDeleteOpen: false,
        currentTab: 0, // 0,1,2
        isCurrentYear: true,
        selectedManagerId: null
    });

    const initYears = async () => {
        const y = [];
        for (let index = 2022; index <= currentYear + 2; index++) {
            y.push({ id: index, name: index });
        }
        setYears([...y]);
    };

    const retrieveRoles = async () => {
        const result = await getRolesDropdown().catch((e) => console.log(e.message));

        if (result.data) {
            setRoles([...result.data]);
        }
    };

    const setCurrentTab = (currentState, index) => {
        const data = { ...currentState };
        data.currentTab = index;
        setState({ ...data });
    };

    const generateManagerObjects = (data) => {
        const year = getCurrentYear();
        let useFullWeek = moment().weeksInYear() - (moment().week() - 1);

        if (filters.year === year) {
            useFullWeek = moment().weeksInYear() - (moment().week() - 1);
        } else if (filters.year > year) {
            useFullWeek = moment().weeksInYear();
        } else if (filters.year < year) {
            useFullWeek = 0;
        }

        const userData = [];
        const totalCounts = {
            r1Done: 0,
            r1DoPerWeek: 0,
            useFullWeek,
            r1Needed: 0,
            goals: 0,
            goalsDone: 0
        };

        data?.['Supervisore 2']?.forEach((element) => {
            const user = { ...element, useFullWeek };
            if (user.goals && user.goals.length && user.goals[0].goals) {
                user.goals = user.goals[0].goals;
                user.structuralGoals = user.goals;
            } else {
                user.goals = 0;
                user.structuralGoals = 0;
            }
            user.totalDone = user.candidatesDone;
            user.r1Needed = Math.round((user.goals / 3) * 100);
            user.r1DoPerWeek = Number.isNaN(user.r1Needed / useFullWeek)
                ? 0
                : Math.round(user.r1Needed / useFullWeek);
            totalCounts.r1Done += user.r1Done;
            totalCounts.r1DoPerWeek += user.r1DoPerWeek;
            totalCounts.r1Needed += user.r1Needed;
            totalCounts.goals += user.goals;
            totalCounts.goalsDone += user.candidatesDone;
            userData.push(user);
        });

        data?.['Supervisore 1']?.forEach((element) => {
            let total = 0;
            let totalDone = 0;
            const subManagerId = element.subManagers.map((item) => item.id);
            if (subManagerId.length) {
                const filteredUsers = userData.filter((item) => subManagerId.includes(item.id));
                filteredUsers.forEach((info) => {
                    total += info.structuralGoals;
                    // totalDone += info.candidatesDone;
                    totalDone += info.totalDone;
                });
            }
            const user = { ...element, useFullWeek };
            if (user.goals && user.goals.length && user.goals[0].goals) {
                user.goals = user.goals[0].goals;
                user.structuralGoals = user.goals + total;
            } else {
                user.goals = 0;
                user.structuralGoals = total;
            }
            user.totalDone = user.candidatesDone + totalDone;
            user.r1Needed = Math.round((user.goals / 3) * 100);
            user.r1DoPerWeek = Number.isNaN(user.r1Needed / useFullWeek)
                ? 0
                : Math.round(user.r1Needed / useFullWeek);
            totalCounts.r1Done += user.r1Done;
            totalCounts.r1DoPerWeek += user.r1DoPerWeek;
            totalCounts.r1Needed += user.r1Needed;
            totalCounts.goals += user.goals;
            totalCounts.goalsDone += user.candidatesDone;
            userData.push(user);
        });

        data?.Diretto?.forEach((element) => {
            let total = 0;
            let totalDone = 0;
            const subManagerId = element.subManagers.map((item) => item.id);
            if (subManagerId.length) {
                const filteredUsers = userData.filter((item) => subManagerId.includes(item.id));
                filteredUsers.forEach((info) => {
                    console.log('info', info);
                    total += info.structuralGoals;
                    // totalDone += info.candidatesDone;
                    totalDone += info.totalDone;
                });
            }

            const user = { ...element, useFullWeek };
            if (user.goals && user.goals.length && user.goals[0].goals) {
                user.goals = user.goals[0].goals;
                user.structuralGoals = user.goals + total;
            } else {
                user.goals = 0;
                user.structuralGoals = total;
            }
            user.totalDone = user.candidatesDone + totalDone;
            user.r1Needed = Math.round((user.goals / 3) * 100);
            user.r1DoPerWeek = Number.isNaN(user.r1Needed / useFullWeek)
                ? 0
                : Math.round(user.r1Needed / useFullWeek);
            totalCounts.r1Done += user.r1Done;
            totalCounts.r1DoPerWeek += user.r1DoPerWeek;
            totalCounts.r1Needed += user.r1Needed;
            totalCounts.goals += user.goals;
            totalCounts.goalsDone += user.candidatesDone;
            userData.push(user);
        });

        data?.['Assistente Manager']?.forEach((element) => {
            const user = { ...element, useFullWeek };
            user.goals = 0;
            user.structuralGoals = 0;
            user.totalDone = 0;
            user.r1Needed = 0;
            user.r1DoPerWeek = 0;
            userData.push(user);
        });

        data?.['Assistente HR Region']?.forEach((element) => {
            let total = 0;
            let totalDone = 0;
            const subManagerId = element.subManagers.map((item) => item.id);
            if (subManagerId.length) {
                const filteredUsers = userData.filter((item) => subManagerId.includes(item.id));
                filteredUsers.forEach((info) => {
                    total += info.structuralGoals;
                    // totalDone += info.candidatesDone;
                    totalDone += info.totalDone;
                });
            }

            const user = { ...element, useFullWeek };
            if (user.goals && user.goals.length && user.goals[0].goals) {
                user.goals = user.goals[0].goals;
                user.structuralGoals = user.goals + total;
            } else {
                user.goals = 0;
                user.structuralGoals = total;
            }
            user.totalDone = user.candidatesDone + totalDone;
            user.r1Needed = Math.round((user.goals / 3) * 100);
            user.r1DoPerWeek = Number.isNaN(user.r1Needed / useFullWeek)
                ? 0
                : Math.round(user.r1Needed / useFullWeek);
            totalCounts.r1Done += user.r1Done;
            totalCounts.r1DoPerWeek += user.r1DoPerWeek;
            totalCounts.r1Needed += user.r1Needed;
            totalCounts.goals += user.goals;
            totalCounts.goalsDone += user.candidatesDone;
            userData.push(user);
        });

        data?.Amministratore?.forEach((element) => {
            let total = 0;
            let totalDone = 0;
            const subManagerId = element.subManagers.map((item) => item.id);
            if (subManagerId.length) {
                const filteredUsers = userData.filter((item) => subManagerId.includes(item.id));
                filteredUsers.forEach((info) => {
                    total += info.structuralGoals;
                    // totalDone += info.candidatesDone;
                    totalDone += info.totalDone;
                });
            }

            const user = { ...element, useFullWeek };
            if (user.goals && user.goals.length && user.goals[0].goals) {
                user.goals = user.goals[0].goals;
                user.structuralGoals = user.goals + total;
            } else {
                user.goals = 0;
                user.structuralGoals = total;
            }
            user.totalDone = user.candidatesDone + totalDone;
            user.r1Needed = Math.round((user.goals / 3) * 100);
            user.r1DoPerWeek = Number.isNaN(user.r1Needed / useFullWeek)
                ? 0
                : Math.round(user.r1Needed / useFullWeek);
            totalCounts.r1Done += user.r1Done;
            totalCounts.r1DoPerWeek += user.r1DoPerWeek;
            totalCounts.r1Needed += user.r1Needed;
            totalCounts.goals += user.goals;
            totalCounts.goalsDone += user.candidatesDone;
            userData.push(user);
        });

        userData.reverse();
        userData.unshift(totalCounts);
        return userData;
    };

    const generateManagerRevenueObjects = (data) => {
        const userData = [];
        const total = {
            r1Week: 0,
            r1Month: 0,
            r1Year: 0,
            fbday: 0,
            inFbday: 0,
            prod: 0,
            inProd: 0,
            revenue: 0,
            inRevenue: 0,
            studio: 0,
            inStudio: 0,
            agreement: 0,
            inAgreement: 0,
            inserito: 0,
            totalInIter: 0
        };

        data.forEach((item) => {
            const user = {};

            user.id = item.id;
            user.name = item.name;
            user.surname = item.surname;
            user.r1Week = item.r1InWeek;
            user.r1Month = item.r1InMonth;
            user.r1Year = item.r1InYear;
            user.fbday = item.fbdayInYear;
            user.inFbday = item.inFbdayForYear;
            user.prod = item.prodInYear;
            user.inProd = item.inProdForYear;
            user.revenue = item.revenueInYear;
            user.inRevenue = item.inRevenueForYear;
            user.studio = item.studioInYear;
            user.inStudio = item.inStudioForYear;
            user.agreement = item.agreementInYear;
            user.inAgreement = item.inAgreementForYear;
            user.inserito = item.inseritoStatusInYear;
            user.totalInIter =
                user.inFbday + user.inProd + user.inRevenue + user.inStudio + user.inAgreement;

            total.r1Week += user.r1Week;
            total.r1Month += user.r1Month;
            total.r1Year += user.r1Year;
            total.fbday += user.fbday;
            total.inFbday += user.inFbday;
            total.prod += user.prod;
            total.inProd += user.inProd;
            total.revenue += user.revenue;
            total.inRevenue += user.inRevenue;
            total.studio += user.studio;
            total.inStudio += user.inStudio;
            total.agreement += user.agreement;
            total.inAgreement += user.inAgreement;
            total.inserito += user.inserito;
            total.totalInIter += user.totalInIter;

            userData.push(user);
        });

        userData.reverse();
        userData.unshift(total);
        return userData;
    };

    const generateManagerMngObjects = (data) => {
        return data.map((item) => {
            return {
                ...item,
                mngFbdayCandidates: item.mngFbdayCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngProdCandidates: item.mngProdCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngRevenueCandidates: item.mngRevenueCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngR2OrR3Candidates: item.mngR2OrR3Candidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                })),
                mngAgreementCandidates: item.mngAgreementCandidates.map((candidate) => ({
                    id: candidate.id,
                    isStudying: candidate.isStudying,
                    name: candidate.fullName,
                    manager: candidate?.manager.fullName
                }))
            };
        });
    };

    const generateQueryString = (currentFilters, qs) => {
        let queryString = qs;

        Object.keys(currentFilters).forEach((key) => {
            const value = currentFilters[key];
            if (value) {
                queryString = buildQueryChunk(queryString, key, value);
            }
        });

        return queryString;
    };

    const retrieveUsersData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersListData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerObjects(result.data);
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const retrieveUsersRevenueData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersRevenueData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerRevenueObjects(result.data);
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const retrieveUsersMngData = async (currentState, currentFilters, queryString) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const qs = generateQueryString(currentFilters, queryString);

        const result = await getUsersMngData(qs).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });

        if (result.data) {
            const userData = generateManagerMngObjects(result.data);
            console.log(userData);
            setManagersData([...userData]);
            data.isLoading = false;
            setState({ ...data });
        }
    };

    const getManagerRevenueDataAndSet = async (id, currentState) => {
        if (id) {
            const newState = { ...currentState };
            newState.isLoading = true;
            setState({ ...newState });

            const qs = generateQueryString(
                { searchString: managerRevenueSearchString, year: filters.year },
                ''
            );

            const result = await getManagerRevenueData(id, qs).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
                newState.isLoading = false;
                setState({ ...newState });
            });

            if (result.data) {
                setManagerRevenueData(result.data);
                newState.isLoading = false;
                setState({ ...newState });
            }
        } else {
            setManagerRevenueData(null);
            setManagerRevenueSearchString('');
        }
    };

    const openManagerModal = (currentState, id) => {
        const newState = { ...currentState };
        newState.selectedManagerId = id;
        setState({ ...newState });
    };

    const closeManagerModel = (currentState) => {
        const newState = { ...currentState };
        newState.selectedManagerId = null;
        setState({ ...newState });
    };

    const onClickCreateUserBtn = (currentState) => {
        const data = { ...currentState };
        data.isUserCandidateOpen = true;
        setState({ ...data });
    };

    const onCloseCreateUserModal = (currentState, reload = false) => {
        const data = { ...currentState };
        data.isUserCandidateOpen = false;
        setState({ ...data });
        if (reload) {
            retrieveUsersData(data, filters, null);
        }
    };

    const handelActiveSwitchToggleHandler = async (event, user) => {
        if (user) {
            const result = await updateUserField(
                user.id,
                { isActive: user.isActive ? 0 : 1 },
                'status'
            ).catch((e) => {
                console.log(e);
                toast.error(MESSAGES.SERVER_ERROR);
            });
            if (result.ok) {
                toast.success(MESSAGES.SUCCESS);
                retrieveUsersData(state, filters, null);
            }
        }
    };

    const filterData = (data, localFilters) => {
        const roleFilters = data.filter((item, index) => {
            if (index === 0) {
                return true;
            }
            if (localFilters.role.length) {
                return item.roleId === localFilters.role;
            }
            return true;
        });

        return roleFilters.filter((item, index) => {
            if (index === 0) {
                return true;
            }
            if (localFilters.searchString.length) {
                return (
                    item?.email?.includes(localFilters.searchString) ||
                    item?.fullName?.includes(localFilters.searchString) ||
                    item?.manager?.fullName?.includes(localFilters.searchString)
                );
            }
            return true;
        });
    };

    const deleteOnClickHandler = (currentState, id) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = true;
        setSelectedUserId(id);
        setState(newState);
    };

    const onCloseDeleteConfirmModal = (currentState) => {
        const newState = { ...currentState };
        newState.isConfirmDeleteOpen = false;
        setSelectedUserId(null);
        setState(newState);
    };

    const removeConfirmClickHandler = async (currentState, currentFilters, id) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await deleteUser(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            newState.isLoading = false;
            newState.isConfirmDeleteOpen = false;
            setState(newState);
            setSelectedUserId(null);
            retrieveUsersData(newState, currentFilters);
        }
    };

    const getRows = (data, localFilters) => {
        const filteredData = filterData(data, localFilters);
        return filteredData.map((item, index) => {
            if (index === 0) {
                return {
                    className: '',
                    cells: [
                        {
                            template: 'TOTALE REGION',
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px] font-bold'
                        },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        {
                            template: `${item?.goalsDone}/${item?.goals}`,
                            className: `border-b-lightBlue-600 border-b-2 font-bold ${
                                item?.goalsDone >= item?.candidatesDone
                                    ? 'text-green-500'
                                    : 'text-red-500'
                            }`
                        },
                        {
                            template: item?.r1Needed,
                            className: 'border-b-lightBlue-600 border-b-2 font-bold'
                        },
                        {
                            template: item?.useFullWeek,
                            className: 'border-b-lightBlue-600 border-b-2 font-bold'
                        },
                        {
                            template: item?.r1DoPerWeek,
                            className: 'border-b-lightBlue-600 border-b-2 font-bold'
                        },
                        {
                            template: item?.r1Done,
                            className: 'border-b-lightBlue-600 border-b-2 font-bold'
                        },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' },
                        { template: '', className: 'border-b-lightBlue-600 border-b-2' }
                    ]
                };
            }
            return {
                className: '',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../manager/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={`${item.name} ${item.surname}`}>
                                {`${item.name} ${item.surname}`}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.role?.name,
                        className: 'min-w-[128px]'
                    },
                    {
                        template: item.isManagerRole ? <FontAwesomeIcon icon={faCheck} /> : null,
                        className: 'min-w-[128px] font-bold text-lg text-center text-primary-500'
                    },
                    {
                        template: item?.createdAt,
                        className: 'min-w-[128px]'
                    },
                    {
                        template: item?.email,
                        className: 'min-w-[128px]'
                    },
                    {
                        template:
                            item?.manager?.name && item?.manager?.surname
                                ? `${item?.manager?.name} ${item?.manager?.surname}`
                                : '',
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: `${item?.candidatesDone}/${item?.goals}`,
                        className: `min-w-[128px] font-bold ${
                            item?.candidatesDone >= item?.goals ? 'text-green-500' : 'text-red-500'
                        }`
                    },
                    {
                        template: `${item?.totalDone}/${item?.structuralGoals}`,
                        className: `min-w-[128px] font-bold ${
                            item?.totalDone >= item?.structuralGoals
                                ? 'text-green-500'
                                : 'text-red-500'
                        }`
                    },
                    {
                        template: item?.r1Needed,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.useFullWeek,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.r1DoPerWeek,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: item?.r1Done,
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: (
                            <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                <input
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                    checked={item.isActive}
                                    onClick={(e) => handelActiveSwitchToggleHandler(e, item)}
                                />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                            </label>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    {
                        template: (
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                onClick={() => deleteOnClickHandler(state, item.id)}
                            />
                        ),
                        className: 'min-w-[128px] text-lg text-center text-red-600 cursor-pointer'
                    }
                ]
            };
        });
    };

    const getPercentage = (number1, number2) => {
        let result = 0;

        result =
            Number.isNaN(number1 / number2) || number1 / number2 === Infinity
                ? 0
                : (number1 * 100) / number2;

        return result.toFixed(2);
    };

    const getRevenueRows = (data, currentState) => {
        if (currentState.isCurrentYear) {
            return data.map((item, index) => {
                if (index === 0) {
                    return {
                        className: 'font-bold',
                        cells: [
                            {
                                template: 'TOTALE REGION',
                                className:
                                    'border-b-lightBlue-600 border-b-2 min-w-[128px] font-bold'
                            },
                            {
                                template: item?.r1Week,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.r1Month,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.r1Year,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.fbday}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item.inFbday,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.prod}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.inProd,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.revenue}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.inRevenue,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.studio}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item?.inStudio,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.agreement}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item.inAgreement,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },

                            {
                                template: (
                                    <>
                                        <span>{item?.inserito}</span>
                                        <br />
                                        <span className="text-red-500">
                                            {`(${getPercentage(item?.inserito, item?.agreement)}%)`}
                                        </span>
                                    </>
                                ),
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: item.totalInIter,
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                            },
                            {
                                template: '',
                                className: 'border-b-lightBlue-600 border-b-2 min-w-[50px]'
                            }
                        ]
                    };
                }
                return {
                    className: 'font-bold',
                    cells: [
                        {
                            template: (
                                <Link
                                    to={`../manager/${item.id}`}
                                    className="text-primary-800 cursor-pointer"
                                    title={`${item.name} ${item.surname}`}>
                                    {`${item.name} ${item.surname}`}
                                </Link>
                            ),
                            className: 'min-w-[128px] font-bold'
                        },
                        {
                            template: item?.r1Week,
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.r1Month,
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.r1Year,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.fbday}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item.inFbday,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.prod}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.inProd,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.revenue}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.inRevenue,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.studio}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item?.inStudio,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.agreement}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item.inAgreement,
                            className: 'min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.inserito}</span>
                                    <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.inserito, item?.agreement)}%)`}
                                    </span>
                                </>
                            ),
                            className: 'min-w-[128px]'
                        },
                        {
                            template: item.totalInIter,
                            className: 'min-w-[128px]'
                        },
                        {
                            template: (
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}
                                    onClick={() => openManagerModal(state, item.id)}
                                />
                            ),
                            className:
                                'min-w-[50px] text-lg text-center text-primary-500 cursor-pointer'
                        }
                    ]
                };
            });
        }
        return data.map((item, index) => {
            if (index === 0) {
                return {
                    className: 'font-bold',
                    cells: [
                        {
                            template: 'TOTALE REGION',
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px] font-bold'
                        },
                        // {
                        //     template: item?.r1Week,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },
                        // {
                        //     template: item?.r1Month,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },
                        {
                            template: item?.r1Year,
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },

                        {
                            template: (
                                <>
                                    <span>{item?.fbday}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item.inFbday,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.prod}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item?.inProd,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.revenue}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item?.inRevenue,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.studio}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item?.inStudio,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: (
                                <>
                                    <span>{item?.agreement}</span>
                                    {/* <br />
                                    <span className="text-red-500">
                                        {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                    </span> */}
                                </>
                            ),
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item.inAgreement,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // },

                        {
                            template: item.inserito,
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        },
                        // {
                        //     template: item.totalInIter,
                        //     className: 'border-b-lightBlue-600 border-b-2 min-w-[128px]'
                        // }
                        {
                            template: '',
                            className: 'border-b-lightBlue-600 border-b-2 min-w-[50px]'
                        }
                    ]
                };
            }
            return {
                className: 'font-bold',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../manager/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={`${item.name} ${item.surname}`}>
                                {`${item.name} ${item.surname}`}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    // {
                    //     template: item?.r1Week,
                    //     className: 'min-w-[128px]'
                    // },
                    // {
                    //     template: item?.r1Month,
                    //     className: 'min-w-[128px]'
                    // },
                    {
                        template: item?.r1Year,
                        className: 'min-w-[128px]'
                    },

                    {
                        template: (
                            <>
                                <span>{item?.fbday}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.fbday, item?.r1Year)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item.inFbday,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.prod}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.prod, item?.fbday)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item?.inProd,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.revenue}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.revenue, item?.prod)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item?.inRevenue,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.studio}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.studio, item?.revenue)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item?.inStudio,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: (
                            <>
                                <span>{item?.agreement}</span>
                                {/* <br />
                                <span className="text-red-500">
                                    {`(${getPercentage(item?.agreement, item?.studio)}%)`}
                                </span> */}
                            </>
                        ),
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item.inAgreement,
                    //     className: 'min-w-[128px]'
                    // },

                    {
                        template: item.inserito,
                        className: 'min-w-[128px]'
                    },
                    // {
                    //     template: item.totalInIter,
                    //     className: 'min-w-[128px]'
                    // }
                    {
                        template: (
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                onClick={() => openManagerModal(state, item.id)}
                            />
                        ),
                        className:
                            'min-w-[50px] text-lg text-center text-primary-500 cursor-pointer'
                    }
                ]
            };
        });
    };

    const getMngCell = (items) => {
        const template = [<div className="font-bold mb-1">{items?.length} Candidati</div>];

        items?.forEach((item) => {
            template.push(
                <div className="mb-0.5">
                    <br />
                    <span className={item.isStudying ? 'text-primary-500' : ''}>
                        {item.name}
                    </span>{' '}
                    <span className="text-red-500">({item.manager})</span>
                </div>
            );
        });

        return template;
    };

    const getMngRows = (data) => {
        return data.map((item) => {
            return {
                className: '',
                cells: [
                    {
                        template: (
                            <Link
                                to={`../manager/${item.id}`}
                                className="text-primary-800 cursor-pointer"
                                title={`${item.name} ${item.surname}`}>
                                {`${item.name} ${item.surname}`}
                            </Link>
                        ),
                        className: 'min-w-[128px] font-bold'
                    },
                    { template: getMngCell(item?.mngFbdayCandidates), className: 'min-w-[128px]' },
                    { template: getMngCell(item?.mngProdCandidates), className: 'min-w-[128px]' },
                    {
                        template: getMngCell(item?.mngRevenueCandidates),
                        className: 'min-w-[128px]'
                    },
                    { template: getMngCell(item?.mngR2OrR3Candidates), className: 'min-w-[128px]' },
                    {
                        template: getMngCell(item?.mngAgreementCandidates),
                        className: 'min-w-[128px]'
                    }
                ]
            };
        });
    };

    const handleFilterChange = (e, currentFilters, filter, currentState) => {
        const newFilters = { ...currentFilters };
        const event = { ...e };
        newFilters[filter] = event.target.value;
        if (filter === 'year') {
            const newState = { ...currentState };
            newState.isCurrentYear = event.target.value == currentYear;
            setState({ ...newState });
        }
        setFilters({ ...newFilters });
    };

    const handleLocalFilterChange = (e, currentFilters, filter) => {
        const newFilters = { ...currentFilters };
        if (filter === 'searchString') {
            newFilters[filter] = e;
        } else {
            const event = { ...e };
            newFilters[filter] = event.target.value;
        }
        setFrontFilters({ ...newFilters });
    };

    const getManagerCsvData = (data, localFilters) => {
        const columns = [
            { header: 'Manager', field: 'name' },
            { header: 'Livello', field: 'role' },
            { header: 'MNG Crescita', field: 'mngCrescita' },
            { header: 'Data creazione', field: 'createdAt' },
            { header: 'Mail', field: 'mail' },
            { header: 'Assegnato a', field: 'manager' },
            { header: 'Obiettivo ins. personale', field: 'personalGoals' },
            { header: 'Obiettivo ins. struttura', field: 'structuralGoals' },
            { header: 'R1 necessari', field: 'r1Needed' },
            { header: 'Settimane utili', field: 'useFullWeek' },
            { header: 'R1 da fare a settimana', field: 'r1DoPerWeek' },
            { header: 'R1 fatti', field: 'r1Done' }
        ];
        const filteredData = filterData(data, localFilters);
        const finalData = filteredData.map((item, index) => {
            if (index === 0) {
                return {
                    name: 'TOTALE REGION',
                    role: '',
                    mngCrescita: '',
                    createdAt: '',
                    mail: '',
                    manager: '',
                    personalGoals: '',
                    structuralGoals: `${item?.goalsDone}/${item?.goals}`,
                    r1Needed: item?.r1Needed,
                    useFullWeek: item?.useFullWeek,
                    r1DoPerWeek: item?.r1DoPerWeek,
                    r1Done: item?.r1Done
                };
            }
            return {
                name: `${item.name} ${item.surname}`,
                role: item?.role?.name,
                mngCrescita: item.isManagerRole ? 'Si' : 'No',
                createdAt: item?.createdAt,
                mail: item?.email,
                manager: `${item?.manager?.name} ${item?.manager?.surname}`,
                personalGoals: `${item?.candidatesDone}/${item?.goals}`,
                structuralGoals: `${item?.totalDone}/${item?.structuralGoals}`,
                r1Needed: item?.r1Needed,
                useFullWeek: item?.useFullWeek,
                r1DoPerWeek: item?.r1DoPerWeek,
                r1Done: item?.r1Done
            };
        });

        return { columns, finalData };
    };

    const getRevenueCsvData = (data) => {
        if (state.isCurrentYear) {
            const columns = [
                { header: 'Manager', field: 'name' },
                { header: 'R1 Settimana corrente', field: 'r1Week' },
                { header: 'R1 Mese corrente', field: 'r1Month' },
                { header: 'R1 progressivi', field: 'r1Year' },
                { header: 'FBD progressivi', field: 'fbday' },
                { header: 'Candidati stato fbd', field: 'inFbday' },
                { header: 'Prodotti progressivi', field: 'prod' },
                { header: 'Candidati stato prodotti', field: 'inProd' },
                { header: 'Ricavi progressivi', field: 'revenue' },
                { header: 'Candidati stato ricavi', field: 'inRevenue' },
                { header: 'Proposte progressivi', field: 'studio' },
                { header: 'Candidati stato proposte', field: 'inStudio' },
                { header: 'Prova valutativa progressi', field: 'agreement' },
                { header: 'Candidati stato prova v.', field: 'inAgreement' },
                { header: 'Potenziale codici progressivi', field: 'inserito' },
                { header: 'Totale candidati in iter', field: 'total' }
            ];
            const finalData = data.map((item, index) => {
                if (index === 0) {
                    return {
                        name: 'TOTALE REGION',
                        r1Week: item?.r1Week,
                        r1Month: item?.r1Month,
                        r1Year: item?.r1Year,
                        fbday: `${item?.fbday} (${getPercentage(item?.fbday, item?.r1Year)}%)`,
                        inFbday: item.inFbday,
                        prod: `${item?.prod} (${getPercentage(item?.prod, item?.fbday)}%)`,
                        inProd: item?.inProd,
                        revenue: `${item?.revenue} (${getPercentage(item?.revenue, item?.prod)}%)`,
                        inRevenue: item?.inRevenue,
                        studio: `${item?.studio} (${getPercentage(item?.studio, item?.revenue)}%)`,
                        inStudio: item?.inStudio,
                        agreement: `${item?.agreement} (${getPercentage(
                            item?.agreement,
                            item?.studio
                        )}%)`,
                        inAgreement: item.inAgreement,
                        inserito: `${item?.inserito} (${getPercentage(
                            item?.inserito,
                            item?.agreement
                        )}%)`,
                        total: item.totalInIter
                    };
                }
                return {
                    name: `${item.name} ${item.surname}`,
                    r1Week: item?.r1Week,
                    r1Month: item?.r1Month,
                    r1Year: item?.r1Year,
                    fbday: `${item?.fbday} (${getPercentage(item?.fbday, item?.r1Year)}%)`,
                    inFbday: item.inFbday,
                    prod: `${item?.prod} (${getPercentage(item?.prod, item?.fbday)}%)`,
                    inProd: item?.inProd,
                    revenue: `${item?.revenue} (${getPercentage(item?.revenue, item?.prod)}%)`,
                    inRevenue: item?.inRevenue,
                    studio: `${item?.studio} (${getPercentage(item?.studio, item?.revenue)}%)`,
                    inStudio: item?.inStudio,
                    agreement: `${item?.agreement} (${getPercentage(
                        item?.agreement,
                        item?.studio
                    )}%)`,
                    inAgreement: item.inAgreement,
                    inserito: `${item?.inserito} (${getPercentage(
                        item?.inserito,
                        item?.agreement
                    )}%)`,
                    total: item.totalInIter
                };
            });
            return { columns, finalData };
        }

        const columns = [
            { header: 'Manager', field: 'name' },
            // { header: 'R1 Settimana corrente', field: 'r1Week' },
            // { header: 'R1 Mese corrente', field: 'r1Month' },
            { header: 'R1 progressivi', field: 'r1Year' },
            { header: 'FBD progressivi', field: 'fbday' },
            // { header: 'Candidati stato fbd', field: 'inFbday' },
            { header: 'Prodotti progressivi', field: 'prod' },
            // { header: 'Candidati stato prodotti', field: 'inProd' },
            { header: 'Ricavi progressivi', field: 'revenue' },
            // { header: 'Candidati stato ricavi', field: 'inRevenue' },
            { header: 'Proposte progressivi', field: 'studio' },
            // { header: 'Candidati stato proposte', field: 'inStudio' },
            { header: 'Prova valutativa progressi', field: 'agreement' },
            // { header: 'Candidati stato prova v.', field: 'inAgreement' },
            { header: 'Potenziale codici progressivi', field: 'inserito' }
            // { header: 'Totale candidati in iter', field: 'total' }
        ];
        const finalData = data.map((item, index) => {
            if (index === 0) {
                return {
                    name: 'TOTALE REGION',
                    // r1Week: item?.r1Week,
                    // r1Month: item?.r1Month,
                    r1Year: item?.r1Year,
                    fbday: `${item?.fbday}`,
                    // inFbday: item.inFbday,
                    prod: `${item?.prod}`,
                    // inProd: item?.inProd,
                    revenue: `${item?.revenue}`,
                    // inRevenue: item?.inRevenue,
                    studio: `${item?.studio}`,
                    // inStudio: item?.inStudio,
                    agreement: `${item?.agreement}`,
                    // inAgreement: item.inAgreement,
                    inserito: `${item?.inserito}`
                    // total: item.totalInIter
                };
            }
            return {
                name: `${item.name} ${item.surname}`,
                // r1Week: item?.r1Week,
                // r1Month: item?.r1Month,
                r1Year: item?.r1Year,
                fbday: `${item?.fbday}`,
                // inFbday: item.inFbday,
                prod: `${item?.prod}`,
                // inProd: item?.inProd,
                revenue: `${item?.revenue}`,
                // inRevenue: item?.inRevenue,
                studio: `${item?.studio}`,
                // inStudio: item?.inStudio,
                agreement: `${item?.agreement}`,
                // inAgreement: item.inAgreement,
                inserito: `${item?.inserito}`
                // total: item.totalInIter
            };
        });
        return { columns, finalData };
    };

    const getMngCSVCell = (items) => {
        let template = '';

        items?.forEach((item, index) => {
            if (index === 0) {
                template = `${index + 1}) ${item.name} (${item.manager})`;
            } else {
                template = `${template} ${index + 1}) ${item.name} (${item.manager})`;
            }
        });

        return template;
    };

    const getMngManagerCsvData = (data) => {
        const columns = [
            { header: 'MNG Crescita', field: 'name' },
            { header: 'FBD', field: 'fbd' },
            { header: 'Prodotti', field: 'prod' },
            { header: 'Ricavi', field: 'revenue' },
            { header: 'Pre-proposta', field: 'r2' },
            { header: 'Proposta', field: 'agreement' }
        ];
        const finalData = data.map((item) => {
            return {
                name: `${item.name} ${item.surname}`,
                fbd: getMngCSVCell(item?.mngFbdayCandidates),
                prod: getMngCSVCell(item?.mngProdCandidates),
                revenue: getMngCSVCell(item?.mngRevenueCandidates),
                r2: getMngCSVCell(item?.mngR2OrR3Candidates),
                agreement: getMngCSVCell(item?.mngAgreementCandidates)
            };
        });

        return { columns, finalData };
    };

    const downloadCsv = (data, localFilters) => {
        let result = null;

        if (state.currentTab === 0) {
            result = getManagerCsvData(data, localFilters);
        } else if (state.currentTab === 1) {
            result = getRevenueCsvData(data);
        } else if (state.currentTab === 2) {
            result = getMngManagerCsvData(data);
        }

        if (result && result.columns && result.finalData) {
            generateAndDownloadCSV(result.columns, result.finalData, 'Manager');
        }
    };

    const resetFrontFilters = () => {
        setFrontFilters({
            searchString: '',
            role: ''
        });
    };

    /**
     * Watchers
     */
    useEffect(() => {
        getManagerRevenueDataAndSet(state.selectedManagerId, state);
    }, [state.selectedManagerId, managerRevenueSearchString]);

    useEffect(() => {
        resetFrontFilters();
    }, [state.currentTab]);

    useEffect(() => {
        if (state.isCurrentYear) {
            setRevenueColumns([...USERS_REVENUE_TABLE_COLUMNS]);
        } else {
            setRevenueColumns([...USERS_REVENUE_TABLE_ONLY_PROG_COLUMNS]);
        }
    }, [state.isCurrentYear]);

    useEffect(() => {
        if (state.currentTab === 0) {
            retrieveUsersData(state, filters, null);
        } else if (state.currentTab === 1) {
            retrieveUsersRevenueData(state, filters, null);
        } else if (state.currentTab === 2) {
            retrieveUsersMngData(state, filters, null);
        }
    }, [filters, state.currentTab]);

    useEffect(() => {
        checkPagePermission(navigate);
        retrieveRoles();
        initYears();
    }, []);

    return (
        <section className="space-y-2">
            {state.isLoading ? <Loader /> : null}

            {state.isUserCandidateOpen ? (
                <CreateManager
                    isOpen
                    isCreateNew
                    closeModel={(reload = false) => onCloseCreateUserModal(state, reload)}
                />
            ) : null}

            {state.isConfirmDeleteOpen ? (
                <ConfirmationModal
                    isOpen={state.isConfirmDeleteOpen}
                    title="Elimina manager"
                    message="Vuoi eliminare il manager scelto?"
                    onConfirmAction={() =>
                        removeConfirmClickHandler(state, filters, selectedUserId)
                    }
                    closeModel={() => onCloseDeleteConfirmModal(state)}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            {state.selectedManagerId != null && (
                <ModalComponent
                    isOpen={!!state.selectedManagerId}
                    closeModel={() => closeManagerModel(state)}
                    title={
                        <div>
                            <span>{managerRevenueData?.user?.fullName}</span>
                            <SearchField
                                className="my-2 mx-auto w-full text-black"
                                defaultValue={filters?.searchString}
                                onPressEnter={(e) => setManagerRevenueSearchString(e)}
                            />
                        </div>
                    }
                    size="max-w-[632px]">
                    <div className="p-6 h-[650px] overflow-y-auto">
                        {managerRevenueData &&
                            revenueColumns.map((item) => {
                                if (
                                    item.template !== 'Manager' &&
                                    item.template !== '' &&
                                    item.template !== 'Totale candidati in iter'
                                ) {
                                    return (
                                        <div>
                                            <div className="font-bold p-2 flex border-b-black border-b-2 w-full justify-between">
                                                <span>{item.template}</span>
                                                <span>
                                                    {managerRevenueData[item.template] &&
                                                        managerRevenueData[item.template]?.length}
                                                </span>
                                            </div>
                                            {managerRevenueData[item.template]?.length > 0 ? (
                                                managerRevenueData[item.template].map(
                                                    (candidate) => {
                                                        return (
                                                            <Link
                                                                to={`../candidate/${candidate.id}`}
                                                                className="w-full cursor-pointer border-b-2 p-2 block"
                                                                title={`${candidate.name} ${candidate.surname}`}>
                                                                {`${candidate.name} ${candidate.surname}`}
                                                            </Link>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <div className="w-full border-b-2 p-2 block">
                                                    Nessun dato
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                            })}
                    </div>
                </ModalComponent>
            )}

            <AppHeader>
                <HeaderItem>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 0 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        onClick={() => setCurrentTab(state, 0)}
                        aria-hidden="true">
                        {managersData.length ? managersData.length - 1 : 0} Manager
                    </span>
                    <span className="text-2xl">|</span>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 1 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        onClick={() => setCurrentTab(state, 1)}
                        aria-hidden="true">
                        River
                    </span>
                    <span className="text-2xl">|</span>
                    <span
                        className={`cursor-pointer font-bold text-2xl text-grey-900 ${
                            state.currentTab === 2 ? 'text-grey-900' : 'text-grey-600'
                        }`}
                        onClick={() => setCurrentTab(state, 2)}
                        aria-hidden="true">
                        MNG Recruiting
                    </span>
                </HeaderItem>
                <HeaderItem>
                    <div className="inline-block input-field-group">
                        <select
                            id="year"
                            name="year"
                            value={filters.year}
                            onChange={(e) => handleFilterChange(e, filters, 'year', state)}>
                            {years.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {state.currentTab === 0 && (
                        <>
                            <div className="inline-block input-field-group">
                                <select
                                    id="role"
                                    name="role"
                                    value={filters.role}
                                    onChange={(e) =>
                                        handleLocalFilterChange(e, frontFilters, 'role')
                                    }>
                                    <option value="">Livello</option>
                                    {roles.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* <div className="flex items-center input-field-group">
                        <span className="inline-block mr-1 font-semibold">Da: </span>
                        <input
                            type="date"
                            value={filters.startDate}
                            placeholder="Da"
                            onChange={(e) => handleFilterChange(e, filters, 'startDate')}
                        />
                    </div>
                    <div className="flex items-center input-field-group">
                        <span className="inline-block mr-1 font-semibold">A: </span>
                        <input
                            type="date"
                            value={filters.endDate}
                            placeholder="A"
                            onChange={(e) => handleFilterChange(e, filters, 'endDate')}
                        />
                    </div> */}
                            <SearchField
                                defaultValue={filters?.searchString}
                                onPressEnter={(e) =>
                                    handleLocalFilterChange(e, frontFilters, 'searchString')
                                }
                            />
                        </>
                    )}
                </HeaderItem>
            </AppHeader>

            {/* <AppHeader>
                <HeaderItem />
                <HeaderItem className="w-80">
                    <div className="inline-block w-full">
                        <SearchField
                            defaultValue={filters?.searchString}
                            onPressEnter={(e) => handleFilterChange(e, filters, 'searchString')}
                        />
                    </div>
                </HeaderItem>
            </AppHeader> */}

            <AppHeader>
                <HeaderItem />
                <HeaderItem>
                    <PrimaryButton onClick={() => downloadCsv(managersData, frontFilters)}>
                        CSV
                    </PrimaryButton>
                    {state.currentTab === 0 && (
                        <PrimaryButton onClick={() => onClickCreateUserBtn(state)}>
                            AGGIUNGI
                        </PrimaryButton>
                    )}
                </HeaderItem>
            </AppHeader>

            {state.currentTab === 0 && (
                <DataTable
                    columns={USERS_TABLE_COLUMNS}
                    rows={getRows(managersData, frontFilters)}
                />
            )}

            {state.currentTab === 1 && (
                <DataTable columns={revenueColumns} rows={getRevenueRows(managersData, state)} />
            )}

            {state.currentTab === 2 && (
                <DataTable
                    columns={USERS_MNG_TABLE_COLUMNS}
                    rows={getMngRows(managersData, frontFilters)}
                />
            )}
        </section>
    );
}

export default Managers;
