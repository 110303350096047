/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';
import { buildQueryChunk } from '../../utility';

export const getRolesDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.ROLE}s`);
    return result;
};

export const getManagersDropdown = async (
    onlyMng = null,
    mng = null,
    candidateId = null,
    subManager = null,
    roleType = null,
    assistantManager = null
) => {
    let queryString = '';

    if (roleType != null) {
        queryString = buildQueryChunk(queryString, 'role', roleType);
    } else if (onlyMng) {
        queryString = buildQueryChunk(queryString, 'onlyMng', true);
    } else {
        if (mng) {
            queryString = buildQueryChunk(queryString, 'mng', true);
        }
        if (subManager) {
            queryString = buildQueryChunk(queryString, 'subManager', true);
        }
        if (candidateId) {
            queryString = buildQueryChunk(queryString, 'candidate', candidateId);
        }
        if (assistantManager) {
            queryString = buildQueryChunk(queryString, 'assistantManager', true);
        }
    }
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.MANAGER}s/dropdown?${queryString}`);
    return result;
};

export const getCandidatesStatusDropdown = async () => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CANDIDATE}-status`);
    return result;
};

export const getItersDropdown = async () => {
    const result = await axiosInstance.get(`v1/iters`);
    return result;
};
