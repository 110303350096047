/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { MESSAGES } from '../../constants';

function YearDropdown({
    label,
    register,
    name,
    config = {},
    errors,
    className,
    minYear = new Date().getFullYear(),
    maxYear = new Date().getFullYear() + 2
}) {
    const [options, setOptions] = useState([]);

    const initYears = async () => {
        const years = [];
        for (let index = minYear; index <= maxYear; index++) {
            years.push({ id: index, name: index });
        }
        setOptions([...years]);
    };

    useEffect(() => {
        initYears();
    }, []);

    return (
        <div
            className={`w-full flex items-center space-x-2 input-field-group ${className} ${
                errors[name] ? 'invalid-field' : ''
            }`}>
            <div className="w-1/3">
                <label htmlFor={name}>{config.required ? `${label}*` : label} : </label>
            </div>
            <div className="w-2/3">
                <select id={name} name={name} {...register(name, { ...config })}>
                    <option value="">Seleziona...</option>
                    {options.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>

            {errors[name]?.type === 'required' && (
                <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
            )}
        </div>
    );
}

export default YearDropdown;
