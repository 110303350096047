/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import { faEnvelope, faLocationDot, faPencil, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import CreateManager from '../../component/modal/CreateManager';
import { MESSAGES, SUB_USER_TABLE_COLUMNS } from '../../constants';
import { getUserById, updateUserPassword } from '../../helper/services/apis/user-services';
import { logoutProcess } from '../../helper/utility';

function UserProfile() {
    const params = useParams();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();
    const [managerData, setManagerData] = useState([]);

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isUpdateUserInfoModalOpen: false
    });

    /**
     * Business Logic
     */

    const updateState = (currentState, key, value) => {
        const data = { ...currentState };
        data[key] = value;
        setState({ ...data });
    };

    const setListType = (currentState, type) => {
        const data = { ...currentState };
        data.listType = type;
        setState({ ...data });
    };

    const onSubmitHandler = async (formData) => {
        const data = { ...state };
        data.isLoading = true;
        setState({ ...data });
        const result = await updateUserPassword(managerData.id, {
            ...formData
        }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            data.isLoading = false;
            setState({ ...data });
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            data.isLoading = false;
            setState({ ...data });
            logoutProcess();
        }
    };

    const retrieveManagerData = async (currentState, id) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const result = await getUserById(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        setManagerData({ ...result.data.data });

        data.isLoading = false;
        setState({ ...data });
    };

    const onCloseCreateUserModal = (currentState, reload = false) => {
        const data = { ...currentState };
        data.isUpdateUserInfoModalOpen = false;
        data.isUpdateUserGoalsModalOpen = false;
        setState({ ...data });
        if (reload) {
            logoutProcess();
        }
    };

    const getRows = (data) => {
        console.log(data);
        return data?.subManagers?.map((item) => ({
            className: '',
            cells: [
                {
                    template: `${item?.name} ${item?.surname}`,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.role?.name,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.createdAt,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.email,
                    className: 'min-w-[128px]'
                },
                {
                    template: `${managerData.name} ${managerData.surname}`,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: `${item?.candidatesDone}/${
                        item?.goals?.goals ? item?.goals?.goals : 0
                    }`,
                    className: `min-w-[128px] font-bold ${
                        item?.candidatesDone >= (item?.goals?.goals ? item?.goals?.goals : 0)
                            ? 'text-green-500'
                            : 'text-red-500'
                    }`
                }
            ]
        }));
    };

    /**
     * Watchers
     */
    useEffect(() => {
        retrieveManagerData(state, params.id);
    }, [params.id]);

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    /**
     * Template
     */
    return (
        <section className="h-full">
            {state.isLoading ? <Loader /> : null}

            {state.isUpdateUserInfoModalOpen || state.isUpdateUserGoalsModalOpen ? (
                <CreateManager
                    isOpen
                    selectedUserId={params.id}
                    isUpdateInfo={state.isUpdateUserInfoModalOpen}
                    closeModel={(reload = false) => onCloseCreateUserModal(state, reload)}
                    isProfile
                />
            ) : null}

            {/* <AppHeader>
                <HeaderItem>
                    <Link className="cursor-pointer text-2xl font-bold" to="../managers">
                        <FontAwesomeIcon icon={faArrowLeft} /> Indietro
                    </Link>
                </HeaderItem>
            </AppHeader> */}

            <div className="h-[calc(100%-108px)] md:flex md:h-[calc(100%-64px)] bg-white py-4 rounded-md">
                {/* left side info section */}
                <div className="flex flex-row md:flex-shrink-0 px-4 md:w-72 xl:w-96 md:border-r-2 md:flex md:flex-col md:justify-between">
                    {/* Top */}
                    <div className="flex justify-between md:block">
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-3">
                            <span className="font-bold md:text-2xl">
                                {`${managerData.name} ${managerData.surname}`}
                            </span>
                            <div className="flex space-x-2 !mt-8">
                                <span>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </span>
                                <span>{managerData.address}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                </span>
                                <span>{managerData.phoneNumber}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                                <span>{managerData.email}</span>
                            </div>
                            <div className="flex justify-end text-primary-500">
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        updateState(state, 'isUpdateUserInfoModalOpen', true)
                                    }
                                />
                            </div>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-4">
                            <div className="flex items-center">
                                <span className="font-bold">AGGIORNA PASSWORD</span>
                            </div>
                            <form
                                className="w-full space-y-4"
                                onSubmit={handleSubmit(onSubmitHandler)}>
                                <div className="w-full input-field-group">
                                    <label htmlFor="currentPassword">Password corrente* : </label>
                                    <input
                                        type="password"
                                        id="currentPassword"
                                        name="currentPassword"
                                        placeholder="Password corrente"
                                        {...register('currentPassword', {
                                            required: true,
                                            maxLength: 50
                                        })}
                                    />
                                    {errors?.currentPassword?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                                <div className="w-full input-field-group">
                                    <label htmlFor="newPassword">Nuova password* : </label>
                                    <input
                                        type="text"
                                        id="newPassword"
                                        name="newPassword"
                                        placeholder="Nuova password"
                                        {...register('newPassword', {
                                            required: true,
                                            maxLength: 50
                                        })}
                                    />
                                    {errors?.newPassword?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                </div>
                                <div className="w-full input-field-group">
                                    <label htmlFor="compareNewPassword">
                                        Conferma nuova password* :{' '}
                                    </label>
                                    <input
                                        type="text"
                                        id="compareNewPassword"
                                        name="compareNewPassword"
                                        placeholder="Conferma nuova password"
                                        {...register('compareNewPassword', {
                                            required: true,
                                            maxLength: 50,
                                            validate: (val) => {
                                                if (watch('newPassword') != val) {
                                                    return 'Your passwords do no match';
                                                }

                                                return null;
                                            }
                                        })}
                                    />
                                    {errors?.compareNewPassword?.type === 'required' && (
                                        <p className="text-red-700">{MESSAGES.FIELD_REQUIRED}</p>
                                    )}
                                    {errors?.compareNewPassword?.type === 'validate' && (
                                        <p className="text-red-700">
                                            {errors?.compareNewPassword?.message}
                                        </p>
                                    )}
                                </div>

                                <PrimaryButton type="submit text-center">AGGIORNA</PrimaryButton>
                            </form>
                        </div>
                    </div>
                </div>
                {/* main info section */}
                <div className="flex-1 overflow-x-hidden overflow-y-auto relative px-4">
                    <AppHeader>
                        <HeaderItem>
                            <span
                                className="cursor-pointer font-bold text-2xl"
                                onClick={() => setListType(state, 'activity')}
                                aria-hidden="true">
                                Manager assegnati
                            </span>
                        </HeaderItem>

                        <HeaderItem>{/* <SearchField /> */}</HeaderItem>
                    </AppHeader>

                    <DataTable columns={SUB_USER_TABLE_COLUMNS} rows={getRows(managerData)} />
                </div>
            </div>
        </section>
    );
}

export default UserProfile;
