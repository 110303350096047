/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContainer from '../component/AuthContainer';
import PrimaryButton from '../component/PrimaryButton';
import { MESSAGES } from '../constants';
import { userSendNewPassword } from '../helper/services/apis/user-services';

function ForgotPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmitHandler = async (data) => {
        // console.log(data);
        const result = await userSendNewPassword(data).catch((e) => toast.error(e.message));
        if (result && result.ok) {
            toast.success('Mail di recupero password inviata');
        } else {
            toast.error(MESSAGES.SERVER_ERROR);
        }
    };

    return (
        <section className="flex w-full h-full justify-center items-center bg-lightBlue-700">
            <AuthContainer>
                <p className="app-label my-6 text-center">Recupera password</p>

                <form className="mt-4 space-y-6" onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="input-field-group">
                        <label className="block mb-2 text-sm font-bold">Mail</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            // className="shadow-sm border border-grey-800 text-sm rounded-lg focus:ring-primary-800 focus:outline-primary-800 block w-full p-2.5"
                            placeholder="Email"
                            {...register('email', { required: true })}
                        />
                        {errors?.email?.type === 'pattern' && (
                            <p className="">{MESSAGES.INVALID_EMAIL}</p>
                        )}
                    </div>
                    <p>
                        Inserisci la tua mail e ti invieremo una nuova password con il quale potrai
                        accedere al portale.
                    </p>

                    <div className="flex justify-between items-center">
                        <Link to="/login" className="text-sm text-primary-800 font-bold">
                            INDIETRO
                        </Link>
                        <PrimaryButton type="submit">RECUPERA</PrimaryButton>
                    </div>
                </form>
            </AuthContainer>
        </section>
    );
}

export default ForgotPassword;
