export const API_ENDPOINT = {
    LOGIN: 'auth/login',
    ROLE: 'role',
    USER: 'user',
    MANAGER: 'manager',
    CANDIDATE: 'candidate',
    ACTIVITY: 'activity',
    ACTIVITIES: 'activities'
};

export const MESSAGES = {
    INVALID_CREDENTIAL: 'Le credenziali inserite non risultano corrette. Controlla e riprova.',
    SUCCESS: 'Completato con successo',
    SERVER_ERROR: 'Errore del server',
    FIELD_REQUIRED: 'Il campo è obbligatorio',
    maxLimitMessage: (limit = '255') => `Questo campo non può superare i ${limit} caratteri`,
    INVALID_EMAIL: `L' email non è valida`,
    INVALID_PHONE_NUMBER: `Il numero di telefono non è valido`
};

export const CANDIDATE_STATUS = {
    RISORSA: { color: '#D1F2EB', text: '#5EA87E' },
    'IN ITER': { color: '#D1F2EB', text: '#5EA87E' },
    'ITER DI RELAZIONE': { color: '#DDE6FF', text: '#488DD2' },
    STOP: { color: '#000000', text: '#FFFFFF' },
    "RECALL PIU' AVANTI": { color: '#FFEBCD', text: '#FEBE56' },
    INSERITO: { color: '#D1F2EB', text: '#5EA87E' }
};

export const ACTIVITY_STATUS_LIST = [
    { label: 'Da fare', value: 'do' },
    { label: 'Fatto', value: 'done' },
    { label: 'Da fissare', value: 'fix' },
    { label: 'Fissato', value: 'fixed' },
    { label: 'Annullato', value: 'canceled' }
];

export const ACTIVITY_STATUS = {
    do: { color: '#FFEBCD', text: '#FEBE56', label: 'Da fare' },
    done: { color: '#D4EADD', text: '#40A46C', label: 'Fatto' },
    fix: { color: '#FFEBCD', text: '#FEBE56', label: 'Da fissare' },
    fixed: { color: '#D4EADD', text: '#40A46C', label: 'Fissato' },
    canceled: { color: '#FFD2D2', text: '#F06F70', label: 'Annullato' }
};
/**
 * REGEX
 */
export const PHONE_REGEX =
    /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/gm;

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}/g;
export const NUMBER_REGEX = /[^\d]+/gim;
export const TWO_DECIMAL_NUMBER_REGEX = /[^\d]+/gim;

/**
 * Tables
 */
export const CANDIDATES_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'MNG Crescita', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidato', isSortable: false, sortStatus: null },
    { className: '', template: 'Raiting', isSortable: false, sortStatus: null },
    { className: '', template: 'Banca', isSortable: false, sortStatus: null },
    { className: '', template: 'Comune filiale', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Cellulare', isSortable: false, sortStatus: null },
    { className: '', template: 'Step iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Azioni da fare', isSortable: false, sortStatus: null },
    { className: '', template: 'Giorni in step', isSortable: false, sortStatus: null },
    { className: '', template: 'Giorni in iter', isSortable: false, sortStatus: null },
    { className: '', template: 'Stato', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const ACTIVITIES_TABLE_COLUMNS = [
    // { className: '', template: 'Data e ora', isSortable: false, sortStatus: null },
    { className: '', template: 'Calendarizzato da', isSortable: false, sortStatus: null },
    { className: '', template: 'Scadenza', isSortable: false, sortStatus: null },
    { className: '', template: 'Completata il', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidato', isSortable: false, sortStatus: null },
    { className: '', template: 'Step', isSortable: false, sortStatus: null },
    { className: '', template: 'Tipo di attività', isSortable: false, sortStatus: null },
    { className: '', template: 'Affidato a', isSortable: false, sortStatus: null },
    { className: '', template: 'Luogo', isSortable: false, sortStatus: null },
    { className: '', template: 'Modalità', isSortable: false, sortStatus: null },
    { className: '', template: 'Esito', isSortable: false, sortStatus: null },
    { className: '', template: 'Note', isSortable: false, sortStatus: null },
    { className: '', template: 'Stato attività', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const CANDIDATE_ACTIVITY_COLUMNS = [
    // { className: '', template: 'Data e ora', isSortable: false, sortStatus: null },
    { className: '', template: 'Inserita da', isSortable: false, sortStatus: null },
    { className: '', template: 'Scadenza', isSortable: false, sortStatus: null },
    { className: '', template: 'Completata il', isSortable: false, sortStatus: null },
    { className: '', template: 'Step', isSortable: false, sortStatus: null },
    { className: '', template: 'Tipo di attività', isSortable: false, sortStatus: null },
    { className: '', template: 'Chi la svolge', isSortable: false, sortStatus: null },
    { className: '', template: 'Luogo', isSortable: false, sortStatus: null },
    { className: '', template: 'Modalità', isSortable: false, sortStatus: null },
    { className: '', template: 'Esito', isSortable: false, sortStatus: null },
    { className: '', template: 'Rating', isSortable: false, sortStatus: null },
    { className: '', template: 'Note', isSortable: false, sortStatus: null },
    { className: '', template: 'Stato attività', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const CANDIDATE_ATTACHMENT_COLUMNS = [
    { className: '', template: 'Nome file', isSortable: false, sortStatus: null },
    { className: '', template: 'Descrizione', isSortable: false, sortStatus: null },
    { className: '', template: 'Data', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Livello', isSortable: false, sortStatus: null },
    { className: '', template: 'MNG Crescita', isSortable: false, sortStatus: null },
    { className: '', template: 'Data creazione', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Assegnato a', isSortable: false, sortStatus: null },
    { className: '', template: 'Obiettivo ins. personale', isSortable: false, sortStatus: null },
    { className: '', template: 'Obiettivo ins. struttura', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 necessari', isSortable: false, sortStatus: null },
    { className: '', template: 'Settimane utili', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 da fare a settimana', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 fatti', isSortable: false, sortStatus: null },
    { className: '', template: 'Attivo', isSortable: false, sortStatus: null },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_REVENUE_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 Settimana corrente', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 Mese corrente', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'FBD progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidati stato fbd', isSortable: false, sortStatus: null },
    { className: '', template: 'Prodotti progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidati stato prodotti', isSortable: false, sortStatus: null },
    { className: '', template: 'Ricavi progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidati stato ricavi', isSortable: false, sortStatus: null },
    { className: '', template: 'Proposte progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidati stato proposte', isSortable: false, sortStatus: null },
    { className: '', template: 'Prova valutativa progressi', isSortable: false, sortStatus: null },
    { className: '', template: 'Candidati stato prova v.', isSortable: false, sortStatus: null },
    // { className: '', template: 'Codici progress.', isSortable: false, sortStatus: null },
    // { className: '', template: 'In corso acquisiz. codici', isSortable: false, sortStatus: null },
    {
        className: '',
        template: 'Potenziale codici progressivi',
        isSortable: false,
        sortStatus: null
    },
    {
        className: '',
        template: 'Totale candidati in iter',
        isSortable: false,
        sortStatus: null
    },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_REVENUE_TABLE_ONLY_PROG_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    // { className: '', template: 'R1 Settimana corrente', isSortable: false, sortStatus: null },
    // { className: '', template: 'R1 Mese corrente', isSortable: false, sortStatus: null },
    { className: '', template: 'R1 progressivi', isSortable: false, sortStatus: null },
    { className: '', template: 'FBD progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato fbd', isSortable: false, sortStatus: null },
    { className: '', template: 'Prodotti progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato prodotti', isSortable: false, sortStatus: null },
    { className: '', template: 'Ricavi progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato ricavi', isSortable: false, sortStatus: null },
    { className: '', template: 'Proposte progressivi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato proposte', isSortable: false, sortStatus: null },
    { className: '', template: 'Prova valutativa progressi', isSortable: false, sortStatus: null },
    // { className: '', template: 'Candidati stato prova v.', isSortable: false, sortStatus: null },
    // { className: '', template: 'Codici progress.', isSortable: false, sortStatus: null },
    // { className: '', template: 'In corso acquisiz. codici', isSortable: false, sortStatus: null },
    {
        className: '',
        template: 'Potenziale codici progressivi',
        isSortable: false,
        sortStatus: null
    },
    // {
    //     className: '',
    //     template: 'Totale candidati in iter',
    //     isSortable: false,
    //     sortStatus: null
    // },
    { className: '', template: '', isSortable: false, sortStatus: null }
];

export const USERS_MNG_TABLE_COLUMNS = [
    { className: '', template: 'MNG Crescita', isSortable: false, sortStatus: null },
    { className: '', template: 'FBD', isSortable: false, sortStatus: null },
    { className: '', template: 'Prodotti', isSortable: false, sortStatus: null },
    { className: '', template: 'Ricavi', isSortable: false, sortStatus: null },
    { className: '', template: 'Pre-proposta', isSortable: false, sortStatus: null },
    { className: '', template: 'Proposta', isSortable: false, sortStatus: null }
];

export const SUB_USER_TABLE_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Livello', isSortable: false, sortStatus: null },
    { className: '', template: 'Data creazione', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Assegnato a', isSortable: false, sortStatus: null },
    {
        className: '',
        template: 'Obiettivo inserimenti personali',
        isSortable: false,
        sortStatus: null
    }
];

export const SHARED_MANAGER_COLUMNS = [
    { className: '', template: 'Manager', isSortable: false, sortStatus: null },
    { className: '', template: 'Mail', isSortable: false, sortStatus: null },
    { className: '', template: 'Cellulare', isSortable: false, sortStatus: null },
    { className: '', template: 'Assegnato a', isSortable: false, sortStatus: null },
    { className: '', template: 'Condividi', isSortable: false, sortStatus: null }
];

/**
 * Forms
 */
export const INIT_USER = {
    name: '',
    surname: '',
    roleId: '',
    email: '',
    isManagerRole: 0,
    phoneNumber: '',
    address: '',
    managerId: '',
    goals: ''
};

export const INIT_CANDIDATE = {
    name: '',
    surname: '',
    phoneNumber: '',
    address: '',
    email: '',
    managerId: '',
    mngManagerId: '',
    statusId: ''
};

export const INIT_ACTIVITY = {
    expireDate: '',
    activityId: '',
    iterId: '',
    activityStatus: '',
    placeId: '',
    modeId: '',
    managerId: '',
    performingManagerId: '',
    candidateId: '9988b00f-2376-4345-9eb1-bfa214e71ba5'
};

export const MONTH = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
];
