/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import {
    faArrowLeft,
    faCamera,
    faChevronDown,
    faEnvelope,
    faLocationDot,
    faPencil,
    faPhone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import profilePlaceholder from '../../assets/images/user_profile_placeholder.jpg';
import CandidateActivities from '../../component/CandidateActivities';
import CandidateAttachments from '../../component/CandidateAttachments';
import CandidateInformation from '../../component/CandidateInformation';
import Loader from '../../component/Loader';
import PrimaryButton from '../../component/PrimaryButton';
import RatingComponent from '../../component/RatingComponent';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import CreateCandidate from '../../component/modal/CreateCandidate';
import { CANDIDATE_STATUS, MESSAGES } from '../../constants';
import {
    getCandidateById,
    updateCandidateFields,
    updateCandidateGeneralFields,
    uploadCandidateProfile
} from '../../helper/services/apis/candidate-services';
import { getCandidatesStatusDropdown } from '../../helper/services/apis/common-services';
import { getTableData } from '../../helper/services/apis/database-services';

function Candidate() {
    const navigate = useNavigate();
    const params = useParams();
    const [candidateData, setCandidateData] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [candidateStatus, setCandidateStatus] = useState(null);
    const [banks, setBanks] = useState([]);
    const [branches, setBranches] = useState([]);
    const [origins, setOrigins] = useState([]);

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isEditCandidateOpen: false,
        isCreateActivityOpen: false,
        isAddNoteOpen: false,
        isAddAttachmentOpen: false,
        isConfirmArchiveOpen: false,
        listType: 'activity' // 'activity'||'attachment'||'info'
    });
    // const [filters, setFilters] = useState({
    //     search: '',
    //     startDate: '',
    //     endDate: '',
    //     role: ''
    // });

    /**
     * Business Logic
     */

    const setListType = (currentState, type) => {
        const data = { ...currentState };
        data.listType = type;
        setState({ ...data });
    };

    const initBanksForFilterDropDown = async () => {
        const result = await getTableData('banks').catch((e) => console.log(e.message));

        if (result.data) {
            setBanks(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const initBranchesForFilterDropDown = async () => {
        const result = await getTableData('branches').catch((e) => console.log(e.message));

        if (result.data) {
            setBranches(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const initOriginsForFilterDropDown = async () => {
        const result = await getTableData('origins').catch((e) => console.log(e.message));

        if (result.data) {
            setOrigins(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const retrieveCandidateData = async (
        currentState,
        id,
        createNote = false,
        addActivity = false
    ) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const result = await getCandidateById(id).catch((e) => {
            console.log(e);
            data.isLoading = false;
            setState({ ...data });
            navigate(`/dashboard`);
        });

        if (result.ok) {
            setCandidateData({ ...result.data });
        }
        data.isAddNoteOpen = createNote;
        data.isCreateActivityOpen = addActivity;
        data.isLoading = false;

        setTimeout(() => {
            setState({ ...data });
        }, 500);
    };

    const reload = (currentState, id, createNote, addActivity) => {
        retrieveCandidateData(currentState, id, createNote, addActivity);
    };

    const updateCandidateField = async (payload, id, currentState) => {
        const result = await updateCandidateFields(id, { ...payload }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            retrieveCandidateData(currentState, id);
        }
    };

    const updateCandidateGeneralInfoField = async (payload, candidateId, id, currentState) => {
        const result = await updateCandidateGeneralFields(id, { ...payload }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            retrieveCandidateData(currentState, candidateId);
        }
    };

    const updateCandidateStatus = (status, id, currentState) => {
        if (status.name === 'STOP' || status.name === 'INSERITO') {
            setCandidateStatus({ ...status });
            setState({ ...currentState, isConfirmArchiveOpen: true });
        } else {
            updateCandidateField({ statusId: status.id }, id, currentState);
        }
    };

    const closeConfirmUpdateCandidateStatus = () => {
        const newState = { ...state };
        newState.isConfirmArchiveOpen = false;
        setState({ ...newState });
        setCandidateStatus(null);
    };

    const confirmUpdateCandidateStatus = async (status, id, currentState) => {
        await updateCandidateField({ statusId: status.id }, id, currentState);
        closeConfirmUpdateCandidateStatus();
    };

    const retrieveStatus = async () => {
        const result = await getCandidatesStatusDropdown().catch((e) => console.log(e.message));

        if (result.data) {
            setStatuses(result.data.filter((item) => item.name !== 'RISORSA'));
        }
    };

    const editCandidateBtnClickHandler = (currentState) => {
        const data = { ...currentState };
        data.isEditCandidateOpen = true;
        setState({ ...data });
    };

    const onCloseEditCandidateModal = (currentState, isReload = false) => {
        const data = { ...currentState };
        data.isEditCandidateOpen = false;
        setState({ ...data });
        if (isReload) {
            retrieveCandidateData({ ...data }, params.id);
        }
    };

    const updateCreateActivityModalState = (currentState, modalState, isReload) => {
        const data = { ...currentState };
        data.isCreateActivityOpen = modalState;
        setState({ ...data });
        if (isReload) {
            retrieveCandidateData({ ...data }, params.id);
        }
    };

    const updateAddNoteModalState = (currentState, modalState, isReload, addActivity = false) => {
        const data = { ...currentState };
        data.isAddNoteOpen = modalState;
        setState({ ...data });
        if (isReload) {
            retrieveCandidateData({ ...data }, params.id, false, addActivity);
        }
    };

    const updateAttachmentModalState = (currentState, modalState, isReload) => {
        const data = { ...currentState };
        data.isAddAttachmentOpen = modalState;
        setState({ ...data });
        if (isReload) {
            retrieveCandidateData({ ...data }, params.id);
        }
    };

    const onProfileImageUpload = async (event, currentState) => {
        event.stopPropagation();
        event.preventDefault();

        const files = [...event.target.files];
        const newState = { ...currentState };
        newState.isLoading = true;
        setState({ ...newState });
        const result = await uploadCandidateProfile(candidateData.id, { media: files[0] });
        if (result.ok) {
            reload(newState, params.id);
        }
        newState.isLoading = false;
        setState({ ...newState });
    };

    const handelKeyUpEventForAdditionalInfo = (event, id, currentState) => {
        if (event.key === 'Enter' && event.keyCode === 13) {
            updateCandidateField({ additionalInfo: event.target.value }, id, currentState);
        }
    };

    /**
     * Watchers
     */
    useEffect(() => {
        retrieveStatus();
        initBanksForFilterDropDown();
        initBranchesForFilterDropDown();
        initOriginsForFilterDropDown();
    }, []);

    useEffect(() => {
        retrieveCandidateData(state, params.id);
    }, [params.id]);

    /**
     * Template
     */
    return (
        <section className="h-full">
            {state.isLoading ? <Loader /> : null}

            {state.isConfirmArchiveOpen ? (
                <ConfirmationModal
                    isOpen={state.isConfirmArchiveOpen}
                    title={`${candidateStatus.name === 'STOP' ? 'Stop' : 'Inserisci'} candidato`}
                    message={`Confermi di voler ${
                        candidateStatus.name === 'STOP' ? 'stoppare' : 'inserire'
                    } il candidato?`}
                    onConfirmAction={() => confirmUpdateCandidateStatus(candidateStatus, params.id)}
                    closeModel={() => closeConfirmUpdateCandidateStatus(state)}
                    confirmBtnText={`${candidateStatus.name === 'STOP' ? 'STOP' : 'INSERISCI'}`}
                />
            ) : null}

            {/* <CreateCandidate selectedCandidate isOpen closeModel={() => console.log('done')} /> */}
            {state.isEditCandidateOpen ? (
                <CreateCandidate
                    selectedCandidate={candidateData}
                    isOpen={state.isEditCandidateOpen}
                    closeModel={(isReload) => onCloseEditCandidateModal(state, isReload)}
                />
            ) : null}

            <AppHeader>
                <HeaderItem>
                    <Link className="cursor-pointer text-2xl font-bold" to="../candidates">
                        <FontAwesomeIcon icon={faArrowLeft} /> Indietro
                    </Link>
                </HeaderItem>
            </AppHeader>

            <div className="h-[calc(100%-108px)] md:flex md:h-[calc(100%-64px)] bg-white py-4 rounded-md">
                {/* left side info section */}
                <div className="flex flex-row md:flex-shrink-0 px-4 w-72 2xl:w-96 md:border-r-2 md:flex md:flex-col md:justify-between overflow-auto">
                    {/* Top */}
                    <div className="flex justify-between md:block">
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 text-center">
                            {/* "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg" */}
                            <div className="inline-block mb-2 relative">
                                <img
                                    src={candidateData?.userProfile || profilePlaceholder}
                                    alt="Girl in a jacket"
                                    width="150"
                                    height="150"
                                    className="bg-auto bg-no-repeat bg-center rounded-full"
                                />
                                {!candidateData?.isArchive && (
                                    <label
                                        htmlFor="photo-upload-input"
                                        className="absolute right-2 bottom-0 text-xl cursor-pointer ">
                                        <FontAwesomeIcon icon={faCamera} />
                                        <input
                                            id="photo-upload-input"
                                            type="file"
                                            onChange={(e) => onProfileImageUpload(e, state)}
                                            className="hidden"
                                            multiple="false"
                                        />
                                    </label>
                                )}
                            </div>

                            <span className="font-bold md:text-2xl block text-left">
                                {candidateData.fullName}
                            </span>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-2">
                            <div className="flex justify-between">
                                <span>Stato :</span>

                                {candidateData && candidateData.status && (
                                    <span className="relative">
                                        <span
                                            className="peer px-4 py-1 rounded-md cursor-pointer space-x-2"
                                            style={{
                                                background:
                                                    CANDIDATE_STATUS[candidateData?.status?.name]
                                                        .color,
                                                color: CANDIDATE_STATUS[candidateData?.status?.name]
                                                    .text
                                            }}>
                                            <span className="font-bold text-sm 2xl:text-base">
                                                {candidateData?.status?.name}
                                            </span>
                                            {!candidateData?.isArchive && (
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            )}
                                        </span>
                                        {!candidateData?.isArchive && (
                                            <div className="absolute right-0 hidden z-20 w-[200px] flex-col rounded bg-white shadow drop-shadow-lg hover:flex peer-hover:flex text-left">
                                                {statuses.map((status) => (
                                                    <span
                                                        key={status.id}
                                                        className="px-5 py-3 cursor-pointer hover:bg-gray-200"
                                                        onClick={() =>
                                                            updateCandidateStatus(
                                                                status,
                                                                params.id,
                                                                state
                                                            )
                                                        }
                                                        aria-hidden="true">
                                                        {status.name}
                                                    </span>
                                                ))}
                                            </div>
                                        )}
                                    </span>
                                )}
                            </div>
                            <div className="flex justify-between">
                                <span>Step iter :</span>{' '}
                                <span className="font-bold">{candidateData?.iter?.name}</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Giorni in step :</span>{' '}
                                <span
                                    className={`flex justify-center items-center border-4 rounded-full w-11 h-11 text-center font-bold ${
                                        candidateData?.currentIterCounter < 11
                                            ? 'border-green-600 text-green-600'
                                            : candidateData?.currentIterCounter < 21
                                            ? 'border-yellow-600 text-yellow-600'
                                            : 'border-red-600 text-red-600'
                                    }`}>
                                    {candidateData?.currentIterCounter
                                        ? candidateData?.currentIterCounter
                                        : 0}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span>Giorni in iter :</span>{' '}
                                <span className="font-bold">
                                    {candidateData?.totalIterCounter
                                        ? candidateData?.totalIterCounter
                                        : 0}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Rating :</span>{' '}
                                <span className="font-bold">
                                    <RatingComponent
                                        readOnly
                                        name="rating"
                                        config={{ required: true }}
                                        defaultValue={candidateData.rating}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-2">
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </span>
                                <span>{candidateData.address}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                </span>
                                <span>{candidateData.phoneNumber}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                                <span>{candidateData.email}</span>
                            </div>
                            {!candidateData?.isArchive && (
                                <div className="flex justify-end text-primary-500">
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        className="cursor-pointer"
                                        onClick={() => editCandidateBtnClickHandler(state)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2  space-y-2">
                            <div className="w-full flex items-center space-x-2 input-field-group">
                                <div className="w-1/3">
                                    <label htmlFor="banca">Banca : </label>
                                </div>
                                <div className="w-2/3">
                                    <select
                                        id="bank"
                                        name="bank"
                                        value={candidateData?.generalInfo?.bankId}
                                        onChange={(e) =>
                                            updateCandidateGeneralInfoField(
                                                { bankId: e.target.value },
                                                params.id,
                                                candidateData?.generalInfo?.id,
                                                state
                                            )
                                        }>
                                        <option value="">Seleziona...</option>
                                        {banks.map((bank) => (
                                            <option key={bank.value} value={bank.value}>
                                                {bank.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="w-full flex items-center space-x-2 input-field-group">
                                <div className="w-1/3">
                                    <label htmlFor="banca">Comune filiale : </label>
                                </div>
                                <div className="w-2/3">
                                    <select
                                        id="branch"
                                        name="branch"
                                        value={candidateData?.generalInfo?.commonBranchId}
                                        onChange={(e) =>
                                            updateCandidateGeneralInfoField(
                                                { commonBranchId: e.target.value },
                                                params.id,
                                                candidateData?.generalInfo?.id,
                                                state
                                            )
                                        }>
                                        <option value="">Seleziona...</option>
                                        {branches.map((branch) => (
                                            <option key={branch.value} value={branch.value}>
                                                {branch.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2  space-y-2">
                            <div className="flex justify-between items-center">
                                <span>Il candidato sta studiando per l’esame</span>
                                <div className="input-field-group">
                                    <input
                                        type="checkbox"
                                        id="privacy"
                                        name="privacy"
                                        placeholder="privacy"
                                        className="cursor-pointer"
                                        checked={candidateData.isStudying}
                                        onChange={(e) =>
                                            updateCandidateField(
                                                { isStudying: e.target.checked },
                                                params.id,
                                                state
                                            )
                                        }
                                        disabled={!!candidateData?.isArchive}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2  space-y-2">
                            <div className="flex justify-between items-center">
                                <span>Privacy</span>
                                <div className="input-field-group">
                                    <input
                                        type="checkbox"
                                        id="privacy"
                                        name="privacy"
                                        placeholder="privacy"
                                        className="cursor-pointer"
                                        checked={candidateData.privacy}
                                        onChange={(e) =>
                                            updateCandidateField(
                                                { privacy: e.target.checked },
                                                params.id,
                                                state
                                            )
                                        }
                                        disabled={!!candidateData?.isArchive}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span>App cornerstone </span>
                                <div className="input-field-group">
                                    <input
                                        type="checkbox"
                                        id="appCornerstone"
                                        name="appCornerstone"
                                        className="cursor-pointer"
                                        placeholder="appCornerstone"
                                        checked={candidateData.appCornerstone}
                                        onChange={(e) =>
                                            updateCandidateField(
                                                { appCornerstone: e.target.checked },
                                                params.id,
                                                state
                                            )
                                        }
                                        disabled={!!candidateData?.isArchive}
                                    />
                                </div>
                            </div>

                            <div className="w-full flex items-center space-x-2 input-field-group">
                                <div className="w-1/3">
                                    <label htmlFor="origin">Provenienza : </label>
                                </div>
                                <div className="w-2/3">
                                    <select
                                        id="origin"
                                        name="origin"
                                        value={candidateData?.originId}
                                        onChange={(e) =>
                                            updateCandidateField(
                                                { originId: e.target.value },
                                                params.id,
                                                state
                                            )
                                        }>
                                        <option value="">Seleziona...</option>
                                        {origins.map((origin) => (
                                            <option key={origin.value} value={origin.value}>
                                                {origin.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="input-field-group">
                                <input
                                    defaultValue={candidateData.additionalInfo}
                                    type="text"
                                    id="privacy"
                                    name="privacy"
                                    maxLength="255"
                                    placeholder="Informazioni aggiunte (premi invio per confermare)"
                                    onKeyDown={(e) =>
                                        handelKeyUpEventForAdditionalInfo(e, params.id, state)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {/* Bottom */}
                    <div>
                        <div className="flex w-full justify-between">
                            <span>
                                <b>Condiviso con : </b> Manager{' '}
                            </span>
                            {!candidateData?.isArchive && (
                                <Link className="text-primary-500" to="./shared">
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        className="cursor-pointer"
                                        onClick={() => editCandidateBtnClickHandler(state)}
                                    />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                {/* main info section */}
                <div className="flex-1 overflow-x-hidden overflow-y-auto relative px-4 overflow-auto">
                    <AppHeader className="items-center">
                        <HeaderItem>
                            <span
                                className={`cursor-pointer font-bold text-2xl ${
                                    state.listType === 'activity'
                                        ? 'text-grey-900'
                                        : 'text-grey-600'
                                }`}
                                onClick={() => setListType(state, 'activity')}
                                aria-hidden="true">
                                Azioni
                            </span>
                            <span className="text-2xl">|</span>
                            <span
                                className={`cursor-pointer font-bold text-2xl ${
                                    state.listType === 'attachment'
                                        ? 'text-grey-900'
                                        : 'text-grey-600'
                                }`}
                                onClick={() => setListType(state, 'attachment')}
                                aria-hidden="true">
                                Allegati ({candidateData?.attachments?.length || 0})
                            </span>
                            <span className="text-2xl">|</span>
                            <span
                                className={`cursor-pointer font-bold text-2xl ${
                                    state.listType === 'info' ? 'text-grey-900' : 'text-grey-600'
                                }`}
                                onClick={() => setListType(state, 'info')}
                                aria-hidden="true">
                                Informazioni
                            </span>
                        </HeaderItem>

                        <HeaderItem>
                            {!candidateData?.isArchive &&
                                (state.listType === 'activity' ? (
                                    <PrimaryButton
                                        onClick={() =>
                                            updateCreateActivityModalState(state, true, false)
                                        }>
                                        AGGIUNGI
                                    </PrimaryButton>
                                ) : state.listType === 'attachment' ? (
                                    <PrimaryButton
                                        onClick={() =>
                                            updateAttachmentModalState(state, true, false)
                                        }>
                                        AGGIUNGI
                                    </PrimaryButton>
                                ) : null)}
                        </HeaderItem>
                    </AppHeader>

                    {state.listType === 'activity' && (
                        <CandidateActivities
                            candidate={candidateData}
                            isAddNoteOpen={state.isAddNoteOpen}
                            isCreateActivityOpen={state.isCreateActivityOpen}
                            updateCreateActivityModalState={(modalState, isReload) =>
                                updateCreateActivityModalState(state, modalState, isReload)
                            }
                            updateAddNoteModalState={(modalState, isReload, addActivity) =>
                                updateAddNoteModalState(state, modalState, isReload, addActivity)
                            }
                            reload={(createNote, addActivity) =>
                                reload(state, params.id, createNote, addActivity)
                            }
                        />
                    )}
                    {state.listType === 'attachment' && (
                        <CandidateAttachments
                            candidate={candidateData}
                            isAddAttachmentOpen={state.isAddAttachmentOpen}
                            updateAttachmentModalState={(modalState, isReload) =>
                                updateAttachmentModalState(state, modalState, isReload)
                            }
                            reload={() => reload(state, params.id)}
                        />
                    )}
                    {state.listType === 'info' && (
                        <CandidateInformation
                            candidate={candidateData}
                            reload={() => reload(state, params.id)}
                        />
                    )}
                </div>
            </div>
        </section>
    );
}

export default Candidate;
