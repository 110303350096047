/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import {
    faArrowLeft,
    faCheck,
    faEnvelope,
    faLocationDot,
    faPencil,
    faPhone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from '../../component/DataTable';
import Loader from '../../component/Loader';
import AppHeader from '../../component/header/AppHeader';
import HeaderItem from '../../component/header/HeaderItem';
import CreateManager from '../../component/modal/CreateManager';
import { MESSAGES, SUB_USER_TABLE_COLUMNS } from '../../constants';
import { getUserById } from '../../helper/services/apis/user-services';
import { checkPagePermission, getCurrentYear } from '../../helper/utility';

function Manager() {
    const params = useParams();
    const navigate = useNavigate();
    const [managerData, setManagerData] = useState([]);
    const [managerIters, setManagerIters] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [assignManagers, setAssignManagers] = useState([]);

    /**
     * Component State
     */
    const [state, setState] = useState({
        isLoading: false,
        isUpdateUserInfoModalOpen: false,
        isUpdateUserGoalsModalOpen: false
    });

    /**
     * Business Logic
     */

    const updateState = (currentState, key, value) => {
        const data = { ...currentState };
        data[key] = value;
        setState({ ...data });
    };

    const setListType = (currentState, type) => {
        const data = { ...currentState };
        data.listType = type;
        setState({ ...data });
    };

    const retrieveManagerData = async (currentState, id) => {
        const data = { ...currentState };
        data.isLoading = true;
        setState({ ...data });

        const result = await getUserById(id).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
        });

        const records = result.data.data.assistantManagerAssignManagers.map((item) => ({
            value: item.id,
            label: item.fullName
        }));
        setAssignManagers([...records]);
        setManagerData({ ...result.data.data, assignManagers: [...records] });
        setManagerIters([...result.data.iter]);

        // if (result.data.data.assistantManagerAssignManagers) {
        //     setAssignManagers(
        //         result.data.data.assistantManagerAssignManagers.map((item) => ({
        //             value: item.id,
        //             label: item.fullName
        //         }))
        //     );
        // }

        data.isLoading = false;
        setState({ ...data });
    };

    const onCloseCreateUserModal = (currentState, reload = false) => {
        const data = { ...currentState };
        data.isUpdateUserInfoModalOpen = false;
        data.isUpdateUserGoalsModalOpen = false;
        setState({ ...data });
        if (reload) {
            retrieveManagerData(data, params.id);
        }
    };

    const getRows = (data) => {
        let newData = [];
        if (data?.role?.name === 'Assistente Manager') {
            if (Array.isArray(data?.assistantManagerAssignManagers)) {
                newData = [...data?.assistantManagerAssignManagers];
            }
        } else if (Array.isArray(data?.subManagers)) {
            newData = [...data?.subManagers];
        }
        return newData?.map((item) => ({
            className: '',
            cells: [
                {
                    template: `${item?.name} ${item?.surname}`,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: item?.role?.name,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.createdAt,
                    className: 'min-w-[128px]'
                },
                {
                    template: item?.email,
                    className: 'min-w-[128px]'
                },
                {
                    template: `${managerData.name} ${managerData.surname}`,
                    className: 'min-w-[128px] font-bold'
                },
                {
                    template: `${item?.candidatesDone}/${
                        item?.goals?.[0]?.goals ? item?.goals?.[0]?.goals : 0
                    }`,
                    className: `min-w-[128px] font-bold ${
                        item?.candidatesDone >=
                        (item?.goals?.[0]?.goals ? item?.goals?.[0]?.goals : 0)
                            ? 'text-green-500'
                            : 'text-red-500'
                    }`
                }
            ]
        }));
    };

    /**
     * Watchers
     */
    useEffect(() => {
        checkPagePermission(navigate);
        retrieveManagerData(state, params.id);
    }, [params.id]);

    /**
     * Template
     */
    return (
        <section className="h-full">
            {state.isLoading ? <Loader /> : null}

            {state.isUpdateUserInfoModalOpen || state.isUpdateUserGoalsModalOpen ? (
                <CreateManager
                    isOpen
                    selectedUserId={params.id}
                    isUpdateInfo={state.isUpdateUserInfoModalOpen}
                    isUpdateGoal={state.isUpdateUserGoalsModalOpen}
                    closeModel={(reload = false) => onCloseCreateUserModal(state, reload)}
                />
            ) : null}

            <AppHeader>
                <HeaderItem>
                    <Link className="cursor-pointer text-2xl font-bold" to="../managers">
                        <FontAwesomeIcon icon={faArrowLeft} /> Indietro
                    </Link>
                </HeaderItem>
            </AppHeader>

            <div className="h-[calc(100%-108px)] md:flex md:h-[calc(100%-64px)] bg-white py-4 rounded-md">
                {/* left side info section */}
                <div className="flex flex-row md:flex-shrink-0 px-4 md:w-72 xl:w-96 md:border-r-2 md:flex md:flex-col md:justify-between overflow-auto">
                    {/* Top */}
                    <div className="flex justify-between md:block">
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-3">
                            <span className="font-bold md:text-2xl">
                                {`${managerData.name} ${managerData.surname}`}
                            </span>
                            <div>
                                <span className="md:text-2xl">Stato : </span>
                                <span className="font-bold md:text-2xl">
                                    {managerData.isActive ? 'Attivo' : 'Inattivo'}
                                </span>
                            </div>
                            <div className="flex space-x-2 !mt-8">
                                <span>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </span>
                                <span>{managerData.address}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                </span>
                                <span>{managerData.phoneNumber}</span>
                            </div>
                            <div className="flex space-x-2">
                                <span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                                <span>{managerData.email}</span>
                            </div>
                            <div className="flex justify-between items-center font-bold">
                                <span>MNG Crescita : </span>
                                <div className="input-field-group text-primary-500">
                                    {managerData.isManagerRole ? (
                                        <FontAwesomeIcon icon={faCheck} />
                                    ) : null}
                                    {/* <input
                                        type="checkbox"
                                        id="privacy"
                                        name="privacy"
                                        placeholder="privacy"
                                    /> */}
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="font-bold">Tipologia : </span>
                                <span>{managerData?.role?.name}</span>
                            </div>
                            <div className="flex items-center">
                                <span className="font-bold">Assegnato a : </span>
                                <span>{managerData?.manager?.fullName}</span>
                            </div>
                            <div className="flex justify-end text-primary-500">
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        updateState(state, 'isUpdateUserInfoModalOpen', true)
                                    }
                                />
                            </div>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-2">
                            <div className="flex items-center">
                                <span className="font-bold">Obiettivo inserimento : </span>
                                <span
                                    className={
                                        managerData?.candidatesDone >=
                                        (managerData?.goals?.length > 0
                                            ? managerData.goals[0].goals
                                                ? managerData.goals[0].goals
                                                : 0
                                            : 0)
                                            ? 'text-green-500 font-bold'
                                            : 'text-red-500 font-bold'
                                    }>
                                    {' '}
                                    {`${
                                        managerData?.candidatesDone
                                            ? managerData?.candidatesDone
                                            : 0
                                    }/${
                                        managerData?.goals?.length > 0
                                            ? managerData.goals[0].goals
                                                ? managerData.goals[0].goals
                                                : 0
                                            : 0
                                    }`}
                                </span>
                            </div>
                            <div className="flex items-center">
                                <span className="font-bold">Anno obiettivo : </span>
                                <span>{getCurrentYear()}</span>
                            </div>
                            <div className="flex justify-end text-primary-500">
                                <FontAwesomeIcon
                                    icon={faPencil}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        updateState(state, 'isUpdateUserGoalsModalOpen', true)
                                    }
                                />
                            </div>
                        </div>
                        <div className="border-r-2 md:border-r-0 md:border-b-2 px-2 md:px-0 md:py-2 space-y-2">
                            {managerIters.map((item) => (
                                <div className="flex items-center" key={item.id}>
                                    <span className="font-bold">{item.name} : </span>
                                    <span>{item.candidatesCount}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* main info section */}
                <div className="flex-1 overflow-x-hidden overflow-y-auto relative px-4">
                    <AppHeader>
                        <HeaderItem>
                            <span
                                className="cursor-pointer font-bold text-2xl"
                                onClick={() => setListType(state, 'activity')}
                                aria-hidden="true">
                                Manager assegnati
                            </span>
                        </HeaderItem>

                        {/* <HeaderItem>
                            <SearchField />
                        </HeaderItem> */}
                    </AppHeader>

                    <DataTable columns={SUB_USER_TABLE_COLUMNS} rows={getRows(managerData)} />
                </div>
            </div>
        </section>
    );
}

export default Manager;
