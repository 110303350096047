/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ACTIVITY_STATUS_LIST } from '../../constants';
import { getManagersDropdown } from '../../helper/services/apis/common-services';
import { getTableData } from '../../helper/services/apis/database-services';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import ModalComponent from './ModalComponent';

function ActivityListingFilters({ isOpen, closeModel, filters, handleFilterChange }) {
    const [managers, setManagers] = useState([]);
    const [modes, setModes] = useState([]);
    const [actions, setActions] = useState([]);
    const output = [
        { value: 'positive', label: 'Positivo' },
        { value: 'negative', label: 'Negativo' }
    ];
    const activityStatuses = [...ACTIVITY_STATUS_LIST];

    const iniManagersForFilterDropDown = async () => {
        // getManagersDropdown(onlyMng, mng, candidateId, subManager).catch((e) =>
        const result = await getManagersDropdown(null, null, null, true).catch((e) =>
            console.log(e.message)
        );

        if (result.data) {
            setManagers(result.data.map((item) => ({ value: item.id, label: item.fullName })));
        }
    };

    const initModesForFilterDropDown = async () => {
        const result = await getTableData('modes').catch((e) => console.log(e.message));

        if (result.data) {
            setModes(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    const initActionsForFilterDropDown = async () => {
        const result = await getTableData('actions').catch((e) => console.log(e.message));

        if (result.data) {
            setActions(result.data.map((item) => ({ value: item.id, label: item.name })));
        }
    };

    useEffect(() => {
        iniManagersForFilterDropDown();
        initModesForFilterDropDown();
        initActionsForFilterDropDown();
    }, []);

    return (
        <ModalComponent
            isOpen={isOpen}
            closeModel={() => closeModel()}
            title="Filtri"
            size="max-w-[632px]">
            {/* Modal Body */}
            <div className="p-6 space-y-6">
                <div className="flex flex-col w-full space-y-4">
                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Manager : </label>
                        <Select
                            value={[...filters.managers]}
                            isMulti
                            onChange={(e) => handleFilterChange('managers', e, filters)}
                            options={managers}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Manager"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Modalità : </label>
                        <Select
                            value={[...filters.modes]}
                            isMulti
                            onChange={(e) => handleFilterChange('modes', e, filters)}
                            options={modes}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Modalità"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Azione : </label>
                        <Select
                            value={[...filters.actions]}
                            isMulti
                            onChange={(e) => handleFilterChange('actions', e, filters)}
                            options={actions}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Azione"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Stato : </label>
                        <Select
                            value={[...filters.status]}
                            isMulti
                            onChange={(e) => handleFilterChange('status', e, filters)}
                            options={activityStatuses}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Stato"
                        />
                    </div>

                    <div className="w-full items-center space-y-2 input-field-group">
                        <label htmlFor="name">Esito : </label>
                        <Select
                            value={[...filters.output]}
                            isMulti
                            onChange={(e) => handleFilterChange('output', e, filters)}
                            options={output}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                            placeholder="Esito"
                        />
                    </div>

                    <div className="w-full flex space-x-2">
                        <div className="w-1/2 space-y-2 input-field-group">
                            <label htmlFor="name">Da : </label>
                            <input
                                type="date"
                                value={filters.startDate}
                                placeholder="Da"
                                onChange={(e) =>
                                    handleFilterChange('startDate', e.target.value, filters)
                                }
                            />
                        </div>
                        <div className="w-1/2 space-y-2 input-field-group">
                            <label htmlFor="name">A : </label>
                            <input
                                type="date"
                                value={filters.endDate}
                                placeholder="A"
                                onChange={(e) =>
                                    handleFilterChange('endDate', e.target.value, filters)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Footer */}
            <div className="flex justify-center p-6 space-x-4 rounded-b bg-white">
                <SecondaryButton onClick={() => closeModel(true)}>Rimuovi tutti</SecondaryButton>
                <PrimaryButton onClick={() => closeModel()}>Applica</PrimaryButton>
            </div>
        </ModalComponent>
    );
}

export default ActivityListingFilters;
