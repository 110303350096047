/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
    faFilter,
    faFilterCircleXmark,
    faPencil,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ACTIVITY_STATUS, CANDIDATE_ACTIVITY_COLUMNS, MESSAGES } from '../constants';
import { deleteActivity, updateActivityFields } from '../helper/services/apis/activity-services';
import { reformatDate, truncate } from '../helper/utility';
import DataTable from './DataTable';
import Loader from './Loader';
import PrimaryButton from './PrimaryButton';
import RatingComponent from './RatingComponent';
import AddActivityNote from './modal/AddActivityNote';
import ConfirmationModal from './modal/ConfirmationModal';
import CreateActivity from './modal/CreateActivity';

function CandidateActivities({
    candidate = null,
    isAddNoteOpen = false,
    isCreateActivityOpen = false,
    updateCreateActivityModalState = null,
    updateAddNoteModalState = null,
    reload
}) {
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [state, setState] = useState({
        isLoading: false,
        confirmCancelModalOpen: false,
        confirmDeleteModalOpen: false,
        isRequiredCreateNew: false,
        isRatingListOnly: false
    });

    const onCloseCreateActivityModal = (isReload = false) => {
        setSelectedActivity(false);
        updateCreateActivityModalState(false, isReload);
    };

    const onCloseAddNoteModal = (isReload = false) => {
        setSelectedActivity(false);
        const addActivity = state.isRequiredCreateNew;
        if (addActivity) {
            setState({ ...state, isRequiredCreateNew: false });
        }
        updateAddNoteModalState(false, isReload, addActivity);
    };

    const updateActivityField = async (id, payload, createNote = false, addActivity = false) => {
        const result = await updateActivityFields(id, { ...payload }).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            return false;
        });
        if (result.ok) {
            toast.success(MESSAGES.SUCCESS);
            await reload(createNote, addActivity);
            return true;
        }
        return false;
    };

    const updateOutput = async (activity, value, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await updateActivityField(
            activity.id,
            {
                output: value
            },
            true
        );

        newState.isLoading = false;
        if (result) {
            setSelectedActivity(activity);
            newState.isRequiredCreateNew = true;
        }
        setState(newState);
    };

    const getEsitoCellTemplate = (item) => {
        let template = null;
        if (
            (item.activityStatus === 'do' || item.activityStatus === 'fix') &&
            !candidate?.isArchive
        ) {
            template = (
                <div className="input-field-group">
                    <select onChange={(e) => updateOutput(item, e.target.value, state)} value="">
                        <option value="">Seleziona...</option>
                        <option value="positive">Fatto</option>
                        <option value="negative">Non fatto</option>
                    </select>
                </div>
            );
        } else {
            template =
                // eslint-disable-next-line no-nested-ternary
                item.output === 'positive'
                    ? 'Fatto'
                    : item.output === 'negative'
                    ? 'Non fatto'
                    : null;
        }
        return template;
    };

    const cancelActivity = async (selectedActivityId, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        setState(newState);

        const result = await updateActivityField(selectedActivityId, {
            activityStatus: 'canceled'
        });

        newState.isLoading = false;

        if (result) {
            newState.confirmCancelModalOpen = false;
            setSelectedActivity(null);
            reload();
        }
        setState(newState);
    };

    const deleteSelectedActivity = async (selectedActivityId, currentState) => {
        const newState = { ...currentState };
        newState.isLoading = true;
        newState.confirmDeleteModalOpen = false;
        setState(newState);

        const result = await deleteActivity(selectedActivityId).catch((e) => {
            console.log(e);
            toast.error(MESSAGES.SERVER_ERROR);
            newState.isLoading = false;
            setState(newState);
        });
        newState.isLoading = false;
        setState(newState);
        if (result) {
            toast.success(MESSAGES.SUCCESS);
            setSelectedActivity(null);
            reload();
        }
    };

    const onClickUpdateActivityNote = (activity) => {
        setSelectedActivity({ ...activity });
        updateAddNoteModalState(true, false);
    };

    const onClickEditActivity = (activity) => {
        setSelectedActivity({ ...activity });
        updateCreateActivityModalState(true, false);
    };

    // eslint-disable-next-line no-unused-vars
    const onClickCancelActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.confirmCancelModalOpen = true;
        setSelectedActivity({ ...activity });
        setState(newState);
    };

    const onClickDeleteActivity = (activity, currentState) => {
        const newState = { ...currentState };
        newState.confirmDeleteModalOpen = true;
        setSelectedActivity({ ...activity });
        setState(newState);
    };

    const getNoteCellTemplate = (item) => {
        let template = null;
        if (item.note) {
            template = (
                <>
                    {truncate(item.note, 100)}
                    <br />
                    <span
                        aria-hidden="true"
                        onClick={() => onClickUpdateActivityNote(item)}
                        className="text-primary-500 font-bold cursor-pointer">
                        Espandi
                    </span>
                </>
            );
        } else {
            template = (
                <span
                    aria-hidden="true"
                    onClick={() => onClickUpdateActivityNote(item)}
                    className="text-primary-500 font-bold cursor-pointer">
                    Aggiungi note
                </span>
            );
        }
        return template;
    };

    const getActionTemplate = (item) => {
        let template = null;
        if (!candidate?.isArchive && !item?.isBdActivity) {
            template = (
                <div className="flex justify-between text-lg">
                    <FontAwesomeIcon
                        icon={faPencil}
                        className="text-primary-500 cursor-pointer"
                        onClick={() => onClickEditActivity(item)}
                    />
                    {item.activityStatus === 'do' || item.activityStatus === 'fix' ? (
                        <>
                            {/* <FontAwesomeIcon
                                icon={faXmark}
                                className="text-red-500 cursor-pointer"
                                onClick={() => onClickCancelActivity(item, state)}
                            /> */}
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-red-500 cursor-pointer"
                                onClick={() => onClickDeleteActivity(item, state)}
                            />
                        </>
                    ) : null}
                </div>
            );
        }
        return template;
    };

    const getRows = (data = []) => {
        let sortData = [];
        if (data.length) {
            const completedDates = data.filter((item) => item.completedDate != null);
            const inCompletedDates = data.filter((item) => item.completedDate == null);
            sortData = inCompletedDates.concat(completedDates).filter((item) => {
                if (state.isRatingListOnly) {
                    return item.rating;
                }
                return true;
            });
        }
        return sortData.map((item) => ({
            className:
                item.expiredEmailSent &&
                (item.activityStatus === 'do' || item.activityStatus === 'fix')
                    ? 'bg-red-300'
                    : null,
            cells: [
                // {
                //     template: item.createdAt,
                //     className: 'min-w-[128px] border-lightBlue-600 border-y-2 border-l-2'
                // },
                {
                    template: `${item?.manager?.name} ${item?.manager?.surname}`,
                    className: 'min-w-[128px] font-bold  border-lightBlue-600 border-y-2'
                },
                {
                    template: reformatDate(item.expireDate),
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: item.completedDate ? reformatDate(item.completedDate) : '',
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: item?.isBdActivity ? 'Compleanno' : item?.iter?.name,
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: item?.action?.name,
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: `${item?.performingManager?.name} ${item?.performingManager?.surname}`,
                    className: 'min-w-[128px] font-bold  border-lightBlue-600 border-y-2'
                },
                {
                    template: item?.place?.name,
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: item?.mode?.name,
                    className: 'min-w-[128px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: getEsitoCellTemplate(item),
                    className: 'min-w-[150px]  border-lightBlue-600 border-y-2'
                },
                {
                    template: (
                        <RatingComponent
                            readOnly
                            name="rating"
                            config={{ required: true }}
                            defaultValue={item.rating}
                        />
                    ),
                    className: 'min-w-[128px] border-lightBlue-600 border-y-2'
                },
                {
                    template: getNoteCellTemplate(item),
                    className: 'min-w-[128px] border-lightBlue-600 border-y-2'
                },
                {
                    template: (
                        <span
                            className="px-4 py-2 rounded-sm border-x"
                            style={{
                                background: ACTIVITY_STATUS[item.activityStatus].color,
                                color: ACTIVITY_STATUS[item.activityStatus].text
                            }}>
                            {ACTIVITY_STATUS[item.activityStatus].label}
                        </span>
                    ),
                    className: 'min-w-[150px] font-bold border-lightBlue-600 border-y-2'
                },
                {
                    template: getActionTemplate(item),
                    className: 'min-w-[116px] border-lightBlue-600 border-y-2 border-r-2'
                }
            ]
        }));
    };

    useEffect(() => {
        if (selectedActivity) {
            const activity = candidate.activities.find((a) => a.id === selectedActivity.id);
            if (activity) {
                setSelectedActivity({ ...activity });
            }
        }
    }, [candidate]);

    return (
        <section className="py-2">
            {state.isLoading ? <Loader /> : null}

            {isCreateActivityOpen ? (
                <CreateActivity
                    isOpen={isCreateActivityOpen}
                    selectedActivity={selectedActivity}
                    closeModel={(isReolad) => onCloseCreateActivityModal(isReolad)}
                    candidateId={candidate.id}
                />
            ) : null}

            {isAddNoteOpen && (
                <AddActivityNote
                    isOpen={isAddNoteOpen}
                    selectedActivity={selectedActivity}
                    closeModel={(isReolad) => onCloseAddNoteModal(isReolad)}
                    disabled={!!candidate?.isArchive}
                />
            )}

            {state.confirmCancelModalOpen ? (
                <ConfirmationModal
                    isOpen={state.confirmCancelModalOpen}
                    title="Annulla azione"
                    message="Sei sicuro di voler annullare l’attività selezionata? 
                Una volta annullata non sarà più possibile completarla.
                Continuare?"
                    onConfirmAction={() => cancelActivity(selectedActivity.id, state)}
                    closeModel={() => setState({ ...state, confirmCancelModalOpen: false })}
                    cancelBtnText="INDIETRO"
                    confirmBtnText="ANNULLA"
                />
            ) : null}

            {state.confirmDeleteModalOpen ? (
                <ConfirmationModal
                    isOpen={state.confirmDeleteModalOpen}
                    title="Elimina azione"
                    message="Vuoi eliminare l'azione selezionata?"
                    onConfirmAction={() => deleteSelectedActivity(selectedActivity.id, state)}
                    closeModel={() => setState({ ...state, confirmDeleteModalOpen: false })}
                    confirmBtnText="ELIMINA"
                />
            ) : null}

            <div className="flex w-full">
                <PrimaryButton
                    onClick={() =>
                        setState({ ...state, isRatingListOnly: !state.isRatingListOnly })
                    }
                    className="ml-auto">
                    <FontAwesomeIcon
                        icon={state.isRatingListOnly ? faFilterCircleXmark : faFilter}
                        className="text-white cursor-pointer"
                    />
                </PrimaryButton>
            </div>

            <DataTable
                columns={CANDIDATE_ACTIVITY_COLUMNS}
                rows={getRows(candidate.activities)}
                className="h-[100%]"
            />
        </section>
    );
}

export default CandidateActivities;
