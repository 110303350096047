/* eslint-disable import/prefer-default-export */
import axiosInstance from '../../../config/axios.config';
import { API_ENDPOINT } from '../../../constants';
import { toFormData } from '../../utility';

export const getCandidatesListData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CANDIDATE}s?${queryString}`);
    return result;
};

export const getCandidatesListCSVData = async (queryString) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CANDIDATE}s-csv-data?${queryString}`);
    return result;
};

export const getActivityCandidates = async () => {
    const result = await axiosInstance.get(`v1/activity-candidates`);
    return result;
};

export const getCandidateById = async (id) => {
    const result = await axiosInstance.get(`v1/${API_ENDPOINT.CANDIDATE}/${id}`);
    return result;
};

export const createNewCandidate = async (payload) => {
    const result = await axiosInstance.post(`v1/${API_ENDPOINT.CANDIDATE}`, payload);
    return result;
};

export const updateCandidate = async (id, payload) => {
    const result = await axiosInstance.put(`v1/${API_ENDPOINT.CANDIDATE}/${id}`, payload);
    return result;
};

export const updateCandidateFields = async (id, payload) => {
    const result = await axiosInstance.patch(`v1/${API_ENDPOINT.CANDIDATE}/${id}`, payload);
    return result;
};

export const updateCandidateGeneralFields = async (id, payload) => {
    const result = await axiosInstance.patch(`v1/candidate-general-info/${id}`, payload);
    return result;
};

export const deleteCandidate = async (id) => {
    const result = await axiosInstance.delete(`v1/${API_ENDPOINT.CANDIDATE}/${id}`);
    return result;
};

export const updateCandidateGeneralInfo = async (id, payload) => {
    const result = await axiosInstance.put(
        `v1/${API_ENDPOINT.CANDIDATE}-general-info/${id}`,
        payload
    );
    return result;
};

export const updateAttachment = async (id, payload) => {
    const formData = await toFormData(payload);
    const result = await axiosInstance.post(
        `v1/${API_ENDPOINT.CANDIDATE}-attachment/${id}`,
        formData
    );
    return result;
};

export const uploadAttachment = async (payload) => {
    const result = await axiosInstance.post(
        `v1/${API_ENDPOINT.CANDIDATE}-attachment`,
        await toFormData(payload)
    );
    return result;
};

export const downloadAttachment = async (payload) => {
    const result = await axiosInstance.post(`v1/download-attachment`, payload);
    return result;
};

export const deleteCandidateAttachment = async (id) => {
    const result = await axiosInstance.delete(`v1/candidate-attachment/${id}`);
    return result;
};

export const uploadCandidateProfile = async (id, payload) => {
    const result = await axiosInstance.post(
        `v1/candidate-upload-profile/${id}`,
        await toFormData(payload)
    );
    return result;
};

export const sharedCandidateWithSelectedManagers = async (payload) => {
    const result = await axiosInstance.post(`v1/shared-candidate-with-managers`, payload);
    return result;
};
